import { map, omitBy } from 'lodash'
import keyBy from 'lodash/keyBy'
import { useCallback } from 'react'
import { UseMutationResult, UseQueryOptions, UseQueryResult, useMutation, useQueryClient } from 'react-query'

import { cleanQuery } from 'src/api/utils'
import useAuth from 'src/auth'
import { useCurrentFacility } from 'src/hooks'
import { BrandInfo, FilledBrandInfo } from 'src/hooks/brands'
import { appendDeepImmutable, removeMatchingFromDeepImmutableList } from 'src/utils'

import { Pagination, PaginationOffset, ShipmentStatus, ShipmentsResponse } from './admin'
import { MyQueryKey, getQueryParamsFromObject, useFetchDelete, useFetchUpdate, useTokenQuery } from './query'
import { ErrorResponse } from './request'

export * as admin from './admin'
export { queryConfig } from './query'
export * from './recycled-product'
export type { ErrorResponse } from './request'

export enum RoleId {
  Admin = 'ADMIN',
  OrganizationManager = 'ORGANIZATION_MANAGER',
  InventoryManager = 'INVENTORY_MANAGER',
  BrandManager = 'BRAND_MANAGER',
  ProjectManager = 'PROJECT_MANAGER',
  Demo = 'DEMO',
  Unknown = '',
}

export enum PurchaseOrderItemDestinationTypeId {
  FACILITY = 'FACILITY',
  TBD = 'TBD',
  SOLD_ON = 'SOLD_ON',
}

export enum PurchaseOrderQuantityTypeId {
  SPECIFIC = 'SPECIFIC',
  CURRENT = 'CURRENT',
  ALL = 'ALL',
}

export enum PurchaseOrderStatusId {
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  CONFIRMED = 'CONFIRMED',
}

export enum QualityReportStatusId {
  DRAFT = 'DRAFT',
  CONTESTED = 'CONTESTED',
  RESOLVED = 'RESOLVED',
}

export enum FacilityType {
  Manufacturer = 'MANUFACTURER',
  WasteHandler = 'WASTE_HANDLER',
  Recycler = 'RECYCLER',
}

export interface AuditFieldUser {
  emailAddress: string
  id: string
}

export interface UserWithDetails extends AuditFieldUser {
  fullName?: string
  phoneNumber?: string
}

export interface FacilityBase {
  name?: string
  countryId: string
  countrySubdivisionId?: string
  address?: string
  closestCityName?: string
  typeId: FacilityType
  includeInReporting?: boolean
  facilityIsVerified?: boolean
  isResearched?: boolean
  deletedAt?: Date
  isDeleted?: boolean
  hasActiveMembership?: boolean
  hasInternalCertification?: boolean
  hasExternalCertification?: boolean
  externalCertificateDescription?: string
}

export interface FacilitySummary extends FacilityBase {
  id: number
  organization?: Organization
}

export interface FacilityAsPartnerOption extends FacilityBase {
  id: number
  organization?: OrganizationAsPartnerOption
}

export interface FacilityInDBBase extends FacilityBase {
  id: number
  hasFactoryProfile?: boolean
  firstPossibleReportPeriod?: Date
  lastPossibleReportPeriod?: Date
  balanceKg?: number
  categoryCount?: number
  inventoryModifiedAt?: Date
  websiteUrl?: string
}

export interface Facility {
  id: number
  name: string
  address: string
  typeId: FacilityType
  countryId: string
  countrySubdivisionId: string
  balanceKg: number
  deletedAt: Date | null
  websiteUrl?: string
  hasActiveMembership?: boolean
}

export interface FacilityListResponse {
  facilities: Facility[]
}

export const useFacilities = () =>
  useTokenQuery<FacilityListResponse, ErrorResponse>(['v1/facilities/', { skip: 0, limit: 300 }])

interface OrganizationAsPartnerOption extends OrganizationInDBBase {
  firstManager?: UserWithDetails
}

interface OrganizationInDBBase {
  id: number
  name?: string
}

interface Organization extends OrganizationInDBBase {}

export interface FacilityWithOrganization extends Facility {
  organization: Organization
}

export interface FacilityPartnership {
  isPreferred: boolean
  partnerFacilityId: number
  partnerFacility: FacilityWithOrganization
}

export interface IncomingFacilityPartnership {
  isPreferred: boolean
  facility: FacilityWithOrganization
}

export interface FacilityWithOrganizationAndStock extends FacilityWithOrganization {
  isActive: boolean
  profileFilledPercent?: number
  isLinked?: boolean
  stockAmountKg: number
  brandStockAmountKg: number
  stockLastUpdated: Date
  mostRecentShipment: Date
  cottonStockAmountKg: number
  polyesterStockAmountKg: number
  otherTextileStockAmountKg: number
  nonTextileStockAmountKg: number
  currentBrandStockPercent: number
  wasteToProductionRatioPercent: number
  partnerships: FacilityPartnership[]
}

export interface ManufacturerListResponse {
  manufacturers: FacilityWithOrganizationAndStock[]
}

export interface SuppliersWithOrganizationAndStock extends FacilityWithOrganizationAndStock {
  segregatingPerMonth: number
  filteredSegregatingPerMonth: number
  filteredStockAmountKg: number
  brandIds: number[]
  isPreferredPartner: boolean
  previousDeliveryKg: number
  totalPotentialVolumeKg: number
  filteredPotentialVolumeKg: number
  lastDeliveryDate?: string
  stockLastModified?: string
  recyclerPartnerNames?: string
  lastDeliveryCategoryTransactionId?: number
}

export interface SuppliersWithOrganizationAndStockTotals {
  stockAmountKg: number
  filteredStockAmountKg: number
  organizationCount: number
  facilityCount: number
  countryCount: number
  brandCount: number
  segregatingPerMonth: number
  filteredSegregatingPerMonth: number
  previousDeliveriesKg: number
  productionVolume: number
  totalPotentialVolumeKg: number
  filteredPotentialVolumeKg: number
}

export interface RecyclerSuppliersListResponse {
  manufacturers: SuppliersWithOrganizationAndStock[]
  totals: SuppliersWithOrganizationAndStockTotals
  stockByCountry: RecyclerSuppliersSummaryStockByCountry[]
}

export interface RecyclerSuppliersSummaryStockByCountry {
  countryId: string
  stockAmountKg: number
  cottonStockAmountKg: number
  polyesterStockAmountKg: number
  otherTextileStockAmountKg: number
  nonTextileStockAmountKg: number
  estimatedBrandStockAmountKg: number
  estimatedBrandStockAmountPercent: number
  facilityCount: number
  organizationCount: number
  segregatingPerMonth: number
  stockAmountInterested: number
  productionVolume: number
}
export interface RecyclerSuppliersSummaryTotalStock {
  stockAmountKg: number
  cottonStockAmountKg: number
  polyesterStockAmountKg: number
  otherTextileStockAmountKg: number
  nonTextileStockAmountKg: number
  estimatedBrandStockAmountPercent: number
  facilityCount: number
  organizationCount: number
  segregatingPerMonth: number
  stockAmountInterested: number
  productionVolume: number
}
export interface RecyclerSuppliersSummaryResponse {
  stockByCountry: RecyclerSuppliersSummaryStockByCountry[]
  totalStock: RecyclerSuppliersSummaryTotalStock
}

export interface PurchaseOrderItem {
  id: number
  categoryId: number | null
  quantityTypeId: string | null
  quantityKg: number | null
  supplierUnitPriceCurrencyId: Classifiers['currencies'][0]['id'] | null
  supplierUnitPricePerKg: number | null
  destinationTypeId: number | null
  destinationFacilityId: number | null
}

export interface PurchaseOrderSummaryBase {
  date?: Date
  targetDeliveryDate?: Date
  logisticsPartnerDescription?: string
  logisticsPartnerId?: number
  isOtherLogisticsPartner?: boolean
  shipmentDestinationDescription?: string
  shipmentDestinationId?: number
  isOtherShipmentDestination?: boolean
}

export interface PurchaseOrderSummary extends PurchaseOrderSummaryBase {
  id: number
  facilityId: number
  facility: FacilityAsPartnerOption
  trackingCode: string
  logisticsPartner?: FacilityAsPartnerOption
  shipmentDestination?: FacilityAsPartnerOption
  statusId: string
}

export interface PurchaseOrderItemBase extends PurchaseOrderItemWithoutDestinationBase {
  destinationTypeId?: string
  destinationFacilityId?: number
}

export interface PurchaseOrder extends PurchaseOrderBase {
  id: number
  trackingCode: string
  facilityId: number
  facility: FacilityAsPartnerOption
  qualityLevelIds: string[]
  statusId: string
  items: PurchaseOrderItem[]
  originalItems: PurchaseOrderItem[]
  documents: PurchaseOrderDocument[]
  supplierUnitPriceCurrencyId: string

  updatedBy?: UserWithDetails
  updatedAt?: Date
  createdBy?: UserWithDetails
  createdAt?: Date
}

export interface PurchaseOrderDocument {
  purchaseOrderId: number
  typeId: number
}

interface PurchaseOrderItemWithoutDestinationBase {
  purchaseOrderId?: number
  categoryId?: number
  quantityTypeid?: string
  quantityKg?: number
  supplierUnitPricePerKg?: number
}

interface PurchaseOrderItemWithoutDestination extends PurchaseOrderItemWithoutDestinationBase {
  id: number
  category?: CategoryWithCompositions
}

export interface PurchaseOrderBase extends PurchaseOrderSummaryBase {
  comments: string
  deliveryTermsId?: string
  paymentTermsId?: string
  paymentModeId?: string

  documents?: PurchaseOrderDocument[]
  supplierUnitPriceCurrencyId?: string
}

export interface ReadOnlyPurchaseOrder extends PurchaseOrderBase {
  id: number
  facilityId: number
  facility: FacilityAsPartnerOption
  logisticsPartner?: FacilityAsPartnerOption
  shipmentDestination?: FacilityAsPartnerOption
  qualityLevelIds: string
  statusId: string
  items: PurchaseOrderItemWithoutDestination[]
  documents: PurchaseOrderDocument[]
  createdBy: UserWithDetails
  trackingCode: string
}

export const useCurrentBrandManufacturers = () =>
  useTokenQuery<ManufacturerListResponse, ErrorResponse>(['v1/current-brand/manufacturers/', { skip: 0, limit: 300 }])

export const useCurrentBrandCountryManufacturers = (countryId: string, isLinked: boolean, query?: string) =>
  useTokenQuery<ManufacturerListResponse, ErrorResponse>([
    'v1/current-brand/manufacturers/',
    getQueryParamsFromObject({ skip: 0, limit: 300, 'country-id': countryId, 'is-linked': isLinked, query }),
  ])

interface AssociationUpdate {
  isLinked: boolean
}

export const useCurrentBrandManufacturerAssociationUpdate = (facilityId: number) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate<unknown, AssociationUpdate>('PATCH')
  return useMutation<unknown, ErrorResponse, AssociationUpdate>(
    (values) => patch(`v1/current-brand/manufacturers/${facilityId}`, values),
    {
      onSuccess: async () =>
        Promise.all([
          queryClient.invalidateQueries({
            predicate: (query) => query.queryKey[0] === 'v1/current-brand/manufacturers/',
          }),
          queryClient.invalidateQueries({
            predicate: (query) => query.queryKey[0] === 'v1/current-brand/manufacturer-stock/',
          }),
        ]),
    },
  )
}

export interface RecyclerProductCategory {
  name: string
  detail: string
}

export interface RecyclerWithOrganization extends Facility {
  organization: Organization
  brandName?: string
  productNames: string[] | null
  preferredMaterialSourceNames: string[] | null
  recyclingActivityNames: string[] | null
  recyclingActivityStageNames: string[] | null
  recyclerProductCategories: RecyclerProductCategory[]
  acceptedInputCompositions:
    | { id: number; preferenceId: string; profileProductionCompositionId: number; description?: string }[]
    | null
}

export interface Sort {
  id: string
  desc: boolean
}

export interface RecyclerListResponse {
  recyclers: RecyclerWithOrganization[]
  pagination: Pagination
}

export const useRecyclers = (
  limit: number,
  skip: number,
  query?: string,
  countries?: string[],
  stages?: string[],
  compositionGroups?: string[],
  compositions?: string[],
  outputProductCategories?: string[],
  filterPartners?: boolean,
  sort: Sort[] = [],
) =>
  useTokenQuery<RecyclerListResponse, ErrorResponse>([
    'v1/recyclers/',
    getQueryParamsFromObject({
      limit,
      skip,
      query: cleanQuery(query),
      c: countries,
      s: stages,
      cpg: compositionGroups,
      cp: compositions,
      pc: outputProductCategories,
      filterPartners,
      'sort-by': sort.map((s) => `${s.desc ? '-' : '+'}${s.id}`),
    }),
  ])

export interface RecyclerWithBrands extends RecyclerWithOrganization {
  preferringBrandIds: number[]
}

export interface RecyclerWithBrandsListResponse {
  recyclers: RecyclerWithBrands[]
}

export const useRecyclersWithBrands = () =>
  useTokenQuery<RecyclerWithBrandsListResponse, ErrorResponse>(['v1/recyclers/with-brands/', { skip: 0, limit: 300 }])

export interface Stock {
  stockAmountKg: number
  cottonStockAmountKg: number
  polyesterStockAmountKg: number
  otherTextileStockAmountKg: number
  deadstockAmountKg: number
  nonTextileStockAmountKg: number
  organizationCount: number
  facilityCount: number
  estimatedBrandStockAmountKg: number
  estimatedBrandStockAmountPercent: number
}

export interface ManufacturerStockResponse {
  stockByCountry: (Stock & { countryId: string })[]
  totalStock: Stock
}

export const useCurrentBrandManufacturerStock = (isLinked: boolean | null) =>
  useTokenQuery<ManufacturerStockResponse, ErrorResponse>([
    'v1/current-brand/manufacturer-stock/',
    getQueryParamsFromObject({ skip: 0, limit: 300, 'is-linked': isLinked }),
  ])

export interface RecyclerSupplierStock {
  partnerStockAmountKg: number
  nonPartnerStockAmountKg: number
  openMarketStockAmountKg: number
}

export interface CategoryGroup {
  colorGroupId?: number

  productionMaterialTypeId?: number
  productionWasteTypeId?: number
  productionWasteSubtypeId?: number

  compositions: CategoryCompositionInDBBase[]
}

export interface RecyclerCategoryGroup extends CategoryGroup {
  partnerStockAmountKg?: number
  nonPartnerStockAmountKg?: number
  openMarketStockAmountKg?: number
}

export interface CategoryBrandStock {
  brandId: string
  brandName?: string
  agentId?: number
  agentName?: string
  amountKg: number
}

export const useRecyclerSupplierStockCategories = (facilityId: number, supplierFacilityId: number) =>
  useTokenQuery<FacilityCategoryStockResponse, ErrorResponse>(
    [`v1/recyclers/${facilityId}/suppliers/${supplierFacilityId}/categories-with-stock/`],
    undefined,
    { enabled: !!facilityId && !!supplierFacilityId },
  )

interface HandlersResponse {
  handlers: Handler[]
  pagination: PaginationOffset
}

export const useRecyclerHandlers = (
  facilityId: number | undefined,
  limit: number,
  offset: number,
  query?: string,
  sort: Sort[] = [],
) =>
  useTokenQuery<HandlersResponse, ErrorResponse>(
    [
      `v1/recyclers/${facilityId}/handlers/`,
      getQueryParamsFromObject({
        limit,
        offset,
        query: cleanQuery(query),
        'sort-by': sort.map((s) => `${s.desc ? '-' : '+'}${s.id}`),
      }),
    ],
    undefined,
    {
      enabled: !!facilityId,
    },
  )

export const useRecyclerSupplier = (facilityId?: number, supplierFacilityId?: number) =>
  useTokenQuery<{ supplier: Facility }, ErrorResponse>(
    [`v1/recyclers/${facilityId}/suppliers/${supplierFacilityId}/`],
    undefined,
    { enabled: !!facilityId && !!supplierFacilityId },
  )

export const useRecyclerSupplierManufacturerProfile = (facilityId?: number, supplierFacilityId?: number) =>
  useTokenQuery<unknown, ErrorResponse>(
    [`v1/recyclers/${facilityId}/suppliers/${supplierFacilityId}/profile/`],
    undefined,
    { enabled: !!facilityId && !!supplierFacilityId },
  )

export const useHandlerSupplierManufacturerProfile = (facilityId?: number, supplierFacilityId?: number) =>
  useTokenQuery<unknown, ErrorResponse>(
    [`v1/handlers/${facilityId}/suppliers/${supplierFacilityId}/profile/`],
    undefined,
    { enabled: !!facilityId && !!supplierFacilityId },
  )

export const useCurrentProjectManufacturerProfile = (facilityId?: number) =>
  useTokenQuery<unknown, ErrorResponse>([`v1/current-project/manufacturers/${facilityId}/profile/`], undefined, {
    enabled: !!facilityId,
  })

export const useRecyclerSupplierHandlerProfile = (facilityId?: number, supplierFacilityId?: number) =>
  useTokenQuery<unknown, ErrorResponse>(
    [`v1/recyclers/${facilityId}/handlers/${supplierFacilityId}/profile/`],
    undefined,
    { enabled: !!facilityId && !!supplierFacilityId },
  )

export interface BrandCategoryGroup extends CategoryGroup {
  stockAmountKg?: number
  brandEstimatedStockAmountKg?: number
}

export interface BrandCategoryStockResponse {
  categoryGroups: BrandCategoryGroup[]
}

export const useCurrentBrandCategoryGroups = (countryId: string) =>
  useTokenQuery<BrandCategoryStockResponse, ErrorResponse>([
    'v1/current-brand/category-groups/',
    { skip: 0, limit: 300, 'country-id': countryId },
  ])

export const useCurrentBrandManufacturerProfile = (facilityId: number) =>
  useTokenQuery<unknown, ErrorResponse>([`v1/current-brand/manufacturers/${facilityId}/profile/`], undefined, {
    enabled: !!facilityId,
  })

export const useCurrentBrandRecyclerProfile = (facilityId: number) =>
  useTokenQuery<unknown, ErrorResponse>([`v1/current-brand/recyclers/${facilityId}/profile/`], undefined, {
    enabled: !!facilityId,
  })

export const useCurrentBrandHandlerProfile = (facilityId: number) =>
  useTokenQuery<unknown, ErrorResponse>([`v1/current-brand/handlers/${facilityId}/profile/`], undefined, {
    enabled: !!facilityId,
  })

export const useCurrentBrandFacility = (facilityId: number) =>
  useTokenQuery<{ facility: Facility }, ErrorResponse>([`v1/current-brand/facilities/${facilityId}/`], undefined, {
    enabled: !!facilityId,
  })

interface BrandPartnerResponse {
  preferredPartnerFacilityIds: number[]
}

export const useCurrentBrandPreferredPartners = () =>
  useTokenQuery<BrandPartnerResponse, ErrorResponse>(['v1/current-brand/preferred-partner-facilities/'])

export const useUpdateCurrentBrandPreferredPartners = () => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate<BrandPartnerResponse, BrandPartnerResponse>('PATCH')
  return useMutation<BrandPartnerResponse, ErrorResponse, BrandPartnerResponse>(
    (data) => patch('v1/current-brand/preferred-partner-facilities/', data),
    {
      onSuccess: async (response) => {
        queryClient.setQueryData(['v1/current-brand/preferred-partner-facilities/'], response)
        await Promise.all([queryClient.invalidateQueries([`v1/recyclers/`])])
      },
    },
  )
}

export const usePublicRecyclerProfile = (facilityId: number) =>
  useTokenQuery<unknown, ErrorResponse>([`v1/recyclers/${facilityId}/profile/`], undefined, {
    enabled: !!facilityId,
  })

export const usePublicRecyclerFacility = (facilityId: number) =>
  useTokenQuery<{ facility: Facility }, ErrorResponse>([`v1/recyclers/${facilityId}/`], undefined, {
    enabled: !!facilityId,
  })

export interface UserSummary {
  emailAddress: string
  phoneNumber: string
  roles: { id: number; roleId: RoleId }[]
}
export const useCurrentBrandUsers = () =>
  useTokenQuery<{ users: UserSummary[]; facilityInvitationCode?: string }, ErrorResponse>([`v1/current-brand/users/`])

export const useCurrentBrandUserInvite = () => {
  const queryClient = useQueryClient()
  const post = useFetchUpdate('POST')
  return useMutation<unknown, ErrorResponse>((data: any) => post(`v1/current-brand/users/`, data), {
    onSuccess: async () => queryClient.invalidateQueries([`v1/current-brand/users/`]),
  })
}

export const useCurrentProjectUsers = () =>
  useTokenQuery<{ users: UserSummary[] }, ErrorResponse>([`v1/current-project/users/`])

export const useCurrentProjectUserInvite = () => {
  const queryClient = useQueryClient()
  const post = useFetchUpdate('POST')
  return useMutation<unknown, ErrorResponse>((data: any) => post(`v1/current-project/users/`, data), {
    onSuccess: async () => queryClient.invalidateQueries([`v1/current-project/users/`]),
  })
}

export interface CurrentBrandFacilityCategoryInventoryGroup {
  facility: FacilityWithOrganizationAndStock
  brandEstimatedStockAmountKg: number
}

export const useCurrentBrandInventories = (inventoryIds: number[]) =>
  useTokenQuery<{ inventories: CurrentBrandFacilityCategoryInventoryGroup[] }, ErrorResponse>(
    ['v1/current-brand/inventories-by-facility/', { 'inventory-ids': inventoryIds }],
    undefined,
    { enabled: !!inventoryIds },
  )

export const useCurrentProjectRecyclerProfile = (facilityId: number) =>
  useTokenQuery<unknown, ErrorResponse>([`v1/current-project/recyclers/${facilityId}/profile/`], undefined, {
    enabled: !!facilityId,
  })

export const useCurrentProjectHandlerProfile = (facilityId: number) =>
  useTokenQuery<unknown, ErrorResponse>([`v1/current-project/handlers/${facilityId}/profile/`], undefined, {
    enabled: !!facilityId,
  })

interface FacilityResponse {
  facility: Facility & { organizationManagerEmail?: string }
}
export const useCurrentProjectFacility = (facilityId: number) =>
  useTokenQuery<FacilityResponse, ErrorResponse>([`v1/current-project/facilities/${facilityId}/`], undefined, {
    enabled: !!facilityId,
  })

export interface Handler extends Facility {
  handlerProfile: {
    activities: any[]
    collectionActivityIds: number[]
    sortingTargets: any[]
    outputCategories: any[]
    managedMaterialProductionStageIds: number[]
    managedMaterialWasteTypesIds: number[]
  }
  organization: Organization
  incomingPartnerships?: IncomingFacilityPartnership[]
  factoryProfile?: { brands?: { brandId: number; description: string }[] }
  partnerships?: FacilityPartnership[]
}

// Note: combined HandlerProfileActivityBase and HandlerProfileActivity
// from the backend, split if necessary.
interface HandlerProfileActivity {
  id: number
  recyclingStageId?: number
  recyclingActivityId?: number
  recyclingSubActivityId?: number
  adoptionStageId?: number
  description?: string
  subDescription?: string
  isUsingSubcontractor?: boolean
}

interface HandlerProfileSummary {
  activities: HandlerProfileActivity[]
  managedMaterialProductionStageIds: number[]
  managedMaterialWasteTypesIds: number[]
}

interface FactoryProfileBrandDetail {
  brandId: number
  description?: string
}

interface FactoryProfileSummary {
  brands: FactoryProfileBrandDetail[]
}

interface FacilityAsPartner extends FacilityBase {
  id: number
  organization: Organization
}

interface FacilityPartnershipWithPartner {
  partnerFacilityId: number
  partnerFacility: FacilityAsPartner
  isPreferred: boolean
}

interface FacilityWithHandlerProfile extends FacilityInDBBase {
  handlerProfile?: HandlerProfileSummary
  organization?: Organization
  factoryProfile?: FactoryProfileSummary
  partnerships: FacilityPartnershipWithPartner[]
}
interface UserReportLink {
  id: number
  name: string
  url: string
}
export interface FacilityWithHandlerDetails extends FacilityWithHandlerProfile {
  associatedProjectIds: string[]
  reportLinks: UserReportLink[]
}

export const useCurrentProjectHandlers = (sort: Sort[] = []) =>
  useTokenQuery<HandlersResponse, ErrorResponse>([
    `v1/current-project/handlers/`,
    { 'sort-by': sort.map((s) => `${s.desc ? '-' : '+'}${s.id}`) },
  ])

export const useCurrentProjectManufacturers = () =>
  useTokenQuery<{ manufacturers: Facility[] }, ErrorResponse>([`v1/current-project/manufacturers/`])

export const useFacility = (facilityId?: number | string | null) =>
  useTokenQuery<FacilityResponse, ErrorResponse>([`v1/facilities/${facilityId}/`], undefined, { enabled: !!facilityId })

interface ManufacturerResponse {
  manufacturer: Facility & {
    organizationManagerEmail: string | null
    lastInventoryUpdate: string | null
    numberOfRecentlyUpdatedCategories: number | null
  }
}

export const useCurrentBrandHandlers = (limit: number, offset: number, query?: string, sort: Sort[] = []) =>
  useTokenQuery<HandlersResponse, ErrorResponse>([
    'v1/current-brand/handlers/',
    getQueryParamsFromObject({
      limit,
      offset,
      query: cleanQuery(query),
      'sort-by': sort.map((s) => `${s.desc ? '-' : '+'}${s.id}`),
    }),
  ])

export const useCurrentBrandManufacturer = (facilityId: number) =>
  useTokenQuery<ManufacturerResponse, ErrorResponse>([`v1/current-brand/manufacturers/${facilityId}/`], undefined, {
    enabled: !!facilityId,
  })

export const useFacilityCreate = () => {
  const queryClient = useQueryClient()
  const post = useFetchUpdate('POST')

  return useMutation((data: any) => post('v1/facilities/', data), {
    onSuccess: (response: any) => {
      if (queryClient.getQueryData(['v1/facilities/', { skip: 0, limit: 300 }])) {
        // append to list if list already fetched
        queryClient.setQueryData(['v1/facilities/', { skip: 0, limit: 300 }], (r: any) => ({
          facilities: [...(r.facilities ?? []), response.facility],
        }))
      }
      queryClient.setQueryData([`v1/facilities/${response.facility.id}/`], response)
      if (response.user) {
        // we are adding inventory manager role to user
        queryClient.setQueryData(['v1/current-user/'], (r: any) => ({
          ...r,
          user: response.user,
          activeRole: response.activeRole,
        }))
      }
    },
  })
}

export const useCreateOrganizationWithFacility = () => {
  const queryClient = useQueryClient()
  const post = useFetchUpdate('POST')
  return useMutation<any, ErrorResponse, any>((data: any) => post('v1/current-organization/', data), {
    onSuccess: async (response: any) => {
      await Promise.all([
        queryClient.invalidateQueries(['v1/current-organization/']),
        queryClient.invalidateQueries(['v1/current-user/']),
        queryClient.invalidateQueries(['v1/facilities/', { skip: 0, limit: 300 }]),
      ])
      if (response.user) {
        // we are adding inventory manager role to user
        queryClient.setQueryData(['v1/current-user/'], (r: any) => ({ ...r, user: response.user }))
      }
    },
  })
}

export const useFacilityUpdate = (facilityId: number) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate('PATCH')

  return useMutation((data: any) => patch(`v1/facilities/${facilityId}/`, data), {
    onSuccess: async (response) => {
      await queryClient.invalidateQueries(['v1/facilities/', { skip: 0, limit: 300 }])
      queryClient.setQueryData([`v1/facilities/${facilityId}/`], response)
    },
  })
}

interface FacilityUsersResponse {
  users: any[]
}

export const useFacilityUsers = (facilityId: number) =>
  useTokenQuery<FacilityUsersResponse>([`v1/facilities/${facilityId}/users/`])

export const useFacilityUserInvite = (facilityId: number) => {
  const queryClient = useQueryClient()
  const post = useFetchUpdate('POST')
  return useMutation((data: any) => post(`v1/facilities/${facilityId}/users/`, data), {
    onSuccess: async () => queryClient.invalidateQueries([`v1/facilities/${facilityId}/users/`]),
  })
}
export const useFacilityUserRemove = (facilityId: number) => {
  const queryClient = useQueryClient()
  const del = useFetchDelete()
  return useMutation((userId: string) => del(`v1/facilities/${facilityId}/users/${userId}/`), {
    onSuccess: async () => queryClient.invalidateQueries([`v1/facilities/${facilityId}/users/`]),
  })
}

export interface CategoriesResponse {
  categories: CategoryInDBBase[]
}

export interface GetCategoriesResponse extends CategoriesResponse {
  pagination: Pagination
}

interface CategoryBase {
  color?: CategoryColor
  productionMaterialTypeId: number // should be optional
  productionWasteTypeId: number // should be optional
  productionWasteSubtypeId?: number
  gsm?: string
  description?: string
  isSortedByHandler?: boolean
  isCheckedByRecycler?: boolean
}
export interface CategoryInDBBase extends CategoryBase {
  id: number
  facilityId?: number
  trackingCode: string
  brandId?: number
  brandName?: string
  agentId?: number
  agentName?: string
  certificates: CategoryCertificate[]
}

interface CategoryCompositionForCategory extends CategoryCompositionInDBBase {}

export interface CategoryWithCompositions extends CategoryInDBBase {
  compositions: CategoryCompositionForCategory[]
}

export interface CategoryCertificate {
  productionCertificateId: number
  number: string
}

export const useCategories = (
  facilityId: number,
  queryParams: {
    skip?: number
    limit?: number
    query?: string
    unbranded?: boolean
    brandIds?: string
  } = {},
) => {
  return useTokenQuery<GetCategoriesResponse, ErrorResponse>(
    [`v1/facilities/${facilityId}/categories/`, getQueryParamsFromObject(queryParams)],
    undefined,
    {
      enabled: !!facilityId,
    },
  )
}

export const useFacilityLocations = (facilityId: number) => useTokenQuery([`v1/facilities/${facilityId}/locations/`])

export interface CategoryWithStock extends CategoryWithCompositions {
  certificates: any
  stockLastModified: string
  stockAmountKg: number
  inventoryIds: number[]
  defaultInventoryId: number | null
}
export interface CategoryWithModifications extends CategoryWithStock {
  inventoryModifications: InventoryModification[]
  numberOfInventoryModifications: number
}

export interface FacilityCategoryStockResponse {
  productionTypeIds: number[]
  categories: CategoryWithStock[]
  inventoryIds: number[]
  balanceKg: number
  filteredBalanceKg: number
  pagination: Pagination
}

interface RecycledProductInputCategoryStockResponse {
  balanceKg: number
  brandBalanceKg: number
  inputCategories: CategoryWithStock[]
  inventoryIds: number[]
}

export const useFacilityStockCategories = (
  facilityId: number | undefined,
  purchaseOrderId?: number,
  brandFilter?: FilledBrandInfo,
  query?: string,
  wasteClassifications?: string[],
  compositionClassifications?: string[],
  materialColorGroups?: string[],
  showEmpty: boolean = true,
  limit: number | undefined = undefined,
  skip: number | undefined = undefined,
) => {
  return useTokenQuery<FacilityCategoryStockResponse, ErrorResponse>(
    [
      `v1/facilities/${facilityId}/categories-with-stock/`,
      getQueryParamsFromObject({
        purchaseOrderId,
        ...brandFilter,
        query,
        showEmpty,
        limit,
        skip,
        cg: materialColorGroups,
        wc: wasteClassifications,
        cc: compositionClassifications,
      }),
    ],
    undefined,
    { enabled: !!facilityId },
  )
}

export const useFacilityStockCategoriesWithModifications = (
  facilityId: number,
  purchaseOrderId?: number,
  brandFilter?: FilledBrandInfo,
  query?: string,
) => {
  return useTokenQuery<FacilityCategoryStockResponse, ErrorResponse>(
    [
      `v1/facilities/${facilityId}/categories-with-modifications/`,
      getQueryParamsFromObject({
        purchaseOrderId,
        ...brandFilter,
        query,
      }),
    ],
    undefined,
    { enabled: !!facilityId },
  )
}

export const useRecycledProductBrandInputCategory = (
  facilityId: number,
  product_id: number,
  brandPreferenceId: number,
  inputCategoryIds: number[],
  brandId?: number,
  amount?: number,
) => {
  const path = `v1/recyclers/${facilityId}/categories-with-brand-stock/`
  // TODO: Sort this out
  let obj = {
    'product-id': product_id,
  }
  if (brandId) obj['brand-id'] = brandId
  if (amount) obj['amount'] = amount
  if (brandPreferenceId) obj['brand-preference-id'] = brandPreferenceId
  if (inputCategoryIds) obj['input-category-ids'] = inputCategoryIds
  return useTokenQuery<RecycledProductInputCategoryStockResponse, ErrorResponse>([path, obj], undefined, {
    enabled: !!facilityId,
  })
}

interface Inventory {
  brandId: number | null
  categoryId: number
  facilityId: number | null
  factoryProfileHandlerId: number | null
  lotTrackingCode: string | null
  sourceFacilityId: number
  sourceFacility: Facility | null
}

export interface InventoryModification {
  id: number
  typeId: number
  amount: number
  brandId?: number
  brandName?: string
  brandedAmountKg: number
  agentId?: number
  agentName?: string
  outputTransactionItem: {
    id: number
    inputInventoryModifications: { inventory: { category: { trackingCode: string } } }[]
    transaction: { id: number; trackingCode: string; sourceFacility: Facility; receiveConfirmedAt: Date }
    transactionOrder: { internalCode: string | null }
  } | null
  reportItem: {
    id: number
    transaction: { id: number; trackingCode: string; sourceFacility: Facility; receiveConfirmedAt: Date }
  } | null
  inputTransactionItem: {} | null
  inventoryId: number
  inventory: Inventory
  createdAt: Date
  targetFactoryProfileHandlerId: number | null
  createdBy?: { emailAddress?: string; id?: string }
}

export interface ModificationPartitionAggregate extends FilledBrandInfo {
  quantityGm: number
  remainingQuantityGm?: number
}

export const useInventoryModifications = (inventoryId: number) =>
  useTokenQuery<{ inventoryModifications: InventoryModification[] }, ErrorResponse>(
    [`v1/category-inventories/${inventoryId}/modifications/`],
    undefined,
    {
      enabled: !!inventoryId,
    },
  )

export function useFacilityInventoryModificationsByCategory(
  categoryId: number,
  brandFilter?: FilledBrandInfo,
): UseQueryResult<InventoryModification[], ErrorResponse> {
  return useTokenQuery<{ inventoryModifications: InventoryModification[] }, ErrorResponse, InventoryModification[]>(
    [
      `v1/categories/${categoryId}/inventory-modifications/`,
      {
        limit: 2000,
        ...getQueryParamsFromObject(brandFilter),
      },
    ],
    undefined,
    {
      enabled: !!categoryId,
      select: (r) => r.inventoryModifications,
    },
  )
}

export const usePaginatedFacilityInventoryModificationsByCategory = (
  categoryId: number,
  pageIndex: number,
  sort: { id: string; desc: boolean }[] = [],
  pageSize = 20,
) =>
  useTokenQuery<
    { inventoryModifications: InventoryModification[]; numberOfInventoryModifications: number },
    ErrorResponse,
    { inventoryModifications: InventoryModification[]; pageCount: number }
  >(
    [
      `v1/categories/${categoryId}/inventory-modifications/`,
      { limit: pageSize, skip: pageSize * pageIndex, 'sort-by': sort.map((s) => `${s.desc ? '-' : '+'}${s.id}`) },
    ],
    undefined,
    {
      enabled: !!categoryId,
      select: ({ inventoryModifications, numberOfInventoryModifications }) => ({
        inventoryModifications,
        pageCount: Math.ceil(numberOfInventoryModifications / pageSize),
      }),
    },
  )

export const usePaginatedCategoryInventory = (
  categoryId: number,
  orderId: number,
  pageIndex: number,
  showEmptyVolumes = false,
  pageSize = 20,
) =>
  useTokenQuery<
    { shipmentOrderInventories: CategoryInventory[]; numberOfShipmentOrderInventories: number },
    ErrorResponse,
    { shipmentOrderInventories: CategoryInventory[]; pageCount: number }
  >(
    [
      `v1/categories/${categoryId}/orders/${orderId}/`,
      { limit: pageSize, skip: pageSize * pageIndex, 'include-empty-balances': showEmptyVolumes },
    ],
    undefined,
    {
      enabled: !!categoryId,
      select: ({ shipmentOrderInventories, numberOfShipmentOrderInventories }) => ({
        shipmentOrderInventories,
        pageCount: Math.ceil(numberOfShipmentOrderInventories / pageSize),
      }),
    },
  )

export function useRemainingPartitionsForCategory(
  categoryId: number,
): UseQueryResult<ModificationPartitionAggregate[], ErrorResponse>
export function useRemainingPartitionsForCategory<TData = ModificationPartitionAggregate[]>(
  categoryId: number,
  select: (data: { modificationPartitions: ModificationPartitionAggregate[] }) => TData,
): UseQueryResult<TData, ErrorResponse>
export function useRemainingPartitionsForCategory<TData>(
  categoryId: number,
  select?: (data: { modificationPartitions: ModificationPartitionAggregate[] }) => TData,
): UseQueryResult<TData | ModificationPartitionAggregate[], ErrorResponse> {
  return useTokenQuery<
    { modificationPartitions: ModificationPartitionAggregate[] },
    ErrorResponse,
    TData | ModificationPartitionAggregate[]
  >([`v1/categories/${categoryId}/modification-partitions/`, { limit: 1000 }], undefined, {
    select: select ?? ((r) => r.modificationPartitions),
  })
}

export const useRemainingPartitionsForFacility = (facilityId: number) =>
  useTokenQuery<
    { facilityModificationPartitions: ModificationPartitionAggregate[] },
    ErrorResponse,
    ModificationPartitionAggregate[]
  >([`v1/facilities/${facilityId}/modification-partitions/`], undefined, {
    enabled: !!facilityId,
    select: (r) => r.facilityModificationPartitions ?? [],
  })

export const usePartitionsForOrderItem = (orderId: number, orderItemId: number) =>
  useTokenQuery<
    { modificationPartitions: ModificationPartitionAggregate[] },
    ErrorResponse,
    ModificationPartitionAggregate[]
  >(
    [`v1/category-transaction-orders/${orderId}/items/${orderItemId}/modification-partitions/`, { limit: 1000 }],
    undefined,
    {
      enabled: !!orderId,
      select: (r) => r.modificationPartitions,
    },
  )

type InventoryModificationCreate = BrandInfo & {
  amount: string | number
  typeId?: string | number
  targetFactoryProfileHandlerId?: string | number
  inventory?: {
    facilityId?: string | number
    categoryId: string | number
  }
  inventoryId?: string | number
}

type CategoryInventoryModification = {
  id: number
  inventoryId: number
  inventory: { categoryId: number }
}

export const useAddCategoryInventoryModification = (facilityId: number) => {
  const queryClient = useQueryClient()
  const post = useFetchUpdate<{ modification: CategoryInventoryModification }, InventoryModificationCreate>('POST')
  return useMutation<{ modification: CategoryInventoryModification }, ErrorResponse, InventoryModificationCreate>(
    (data) => post(`v1/facilities/${facilityId}/inventory-modifications/`, data),
    {
      onSuccess: async (data) =>
        await Promise.all([
          queryClient.invalidateQueries([`v1/facilities/${facilityId}/categories-with-stock/`]),
          queryClient.invalidateQueries([`v1/category-inventories/${data.modification.inventoryId}/modifications/`]),
          queryClient.invalidateQueries([`v1/category-inventories/${data.modification.inventoryId}/`]),
          queryClient.invalidateQueries([`v1/categories/${data.modification.inventory.categoryId}/stock/`]),
          queryClient.invalidateQueries([
            `v1/categories/${data.modification.inventory.categoryId}/inventory-modifications/`,
          ]),
          queryClient.invalidateQueries([
            `v1/categories/${data.modification.inventory.categoryId}/modification-partitions/`,
          ]),
        ]),
    },
  )
}

interface CreateCategoryResponse {
  category: CategoryInDBBase
}

export const useCreateCategory = (facilityId: number) => {
  const queryClient = useQueryClient()
  const post = useFetchUpdate<CreateCategoryResponse>('POST')
  return useMutation<CreateCategoryResponse>((data: any) => post(`v1/facilities/${facilityId}/categories/`, data), {
    onSuccess: async () => queryClient.invalidateQueries([`v1/facilities/${facilityId}/categories/`]),
  })
}
export const useCategoryInventory = (inventoryId: number) =>
  useTokenQuery<any, ErrorResponse>([`v1/category-inventories/${inventoryId}/`], undefined, { enabled: !!inventoryId })
export const useCreateCategoryInventory = (facilityId: number) => {
  const queryClient = useQueryClient()
  const post = useFetchUpdate('POST')
  return useMutation((data: any) => post(`v1/facilities/${facilityId}/category-inventories/`, data), {
    onSuccess: async () => queryClient.invalidateQueries([`v1/facilities/${facilityId}/categories-with-stock/`]),
  })
}

interface CategoryWithInventoryIds extends CategoryInDBBase {
  inventoryIds: number[]
}

export const useArchiveCategory = () => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate<{ category: CategoryWithInventoryIds }, { isArchived: boolean }>('PATCH')

  return useMutation<{ category: CategoryWithInventoryIds }, ErrorResponse>(
    (categoryId: any) => patch(`v1/categories/${categoryId}/`, { isArchived: true }),
    {
      onSuccess: async ({ category: { facilityId, inventoryIds } }) => {
        await Promise.all([
          queryClient.invalidateQueries([`v1/facilities/${facilityId}/categories-with-stock/`]),
          ...inventoryIds.map((inventoryId) =>
            queryClient.invalidateQueries([`v1/category-inventories/${inventoryId}/`]),
          ),
        ])
      },
    },
  )
}

interface CategoryFilterOptions {
  materialTypeIds: string[]
  compositionIds: string[]
}

export const useCategoryFilterOptions = () =>
  useTokenQuery<CategoryFilterOptions, ErrorResponse>([`v1/categories/filter-options/`], undefined)

export const useCategory = (categoryId: number) =>
  useTokenQuery([`v1/categories/${categoryId}/`], undefined, { enabled: !!categoryId })
export const useCategoryUpdate = (facilityId: number) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate('PATCH')

  return useMutation(({ categoryId, data }: any) => patch(`v1/categories/${categoryId}/`, data), {
    onSuccess: async () => {
      await Promise.all([queryClient.invalidateQueries([`v1/facilities/${facilityId}/categories/`])])
    },
  })
}

interface CategoryInventory {
  id: number
}

export interface CategoryInventoriesResponse {
  categoryInventories: CategoryInventory[]
}

export const useCategoryInventories = (categoryId: number) =>
  useTokenQuery<CategoryInventoriesResponse, ErrorResponse, CategoryInventoriesResponse['categoryInventories']>(
    [`v1/categories/${categoryId}/inventories/`],
    undefined,
    { select: (data) => data.categoryInventories },
  )

type ReportLink = { id: number; url: string; name: string }

interface CurrentOrganization {
  name: string
  reportLinks: ReportLink[]
  facilities: { id?: number; name: string; reportLinks: ReportLink[] }[]
}

export interface CurrentFacility extends Facility {
  organization: CurrentOrganization
  preferringBrandIds: number[]
  reportLinks: ReportLink[]
}

interface CurrentBrand {
  reportLinks: { id: number; name: string; url: string; isEmbed?: boolean }[]
  membership?: { isActive?: boolean }
}

interface CurrentProject {
  reportLinks: {
    id: number
    name: string
    url: string
    isEmbed?: boolean
  }[]
}

export interface CurrentUser {
  user: {
    fullName: string
    id: string
    emailAddress: string
    isTcAccepted: boolean
    roles: { id: number; roleId: RoleId; facilities: Facility[] }[]
  }
  helpscoutHash?: string
  activeRoleId?: number
  activeRole: {
    id: number
    roleId: RoleId
    facilities: CurrentFacility[]
    organizations: CurrentOrganization[]
    brands: CurrentBrand[]
    projects: CurrentProject[]
    signUpSourceId?: string
  }
}

export const useCurrentUser = () => {
  const { isLoggedIn } = useAuth()
  return useTokenQuery<CurrentUser, ErrorResponse>(['v1/current-user/'], undefined, { enabled: isLoggedIn })
}

export const useCurrentUserActiveRoleUpdate = () => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate('PATCH')
  return useMutation((data: any) => patch(`v1/current-user/active-role/`, data), {
    onSuccess: async (response) => {
      queryClient.setQueryData(['v1/current-user/'], response)
      // remove queries for previous role's requests to avoid conflicts
      // we expect the view to have been redirected by now so old, now possibly unauthorized, queries are not refetched
      queryClient.removeQueries({
        predicate: (query) => !['v1/classifiers/', 'v1/current-user/'].includes(query.queryKey[0] as string),
      })
    },
  })
}

export const useCurrentUserUpdate = () => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate('PATCH')
  return useMutation((data: any) => patch(`v1/current-user/`, data), {
    onSuccess: async () => queryClient.invalidateQueries(['v1/current-user/']),
  })
}

export const useSignUpCodeInfo = (signUpCode: string) =>
  useTokenQuery<any, ErrorResponse>([`v1/sign-ups/invites/${signUpCode}/`], undefined, { enabled: !!signUpCode })

export const useSignUp = () => {
  const post = useFetchUpdate('POST')
  return useMutation((data: any) => post(`v1/sign-ups/`, data))
}

export interface FileAttachment {
  id: number
  createdAt: Date
  name: string
  categoryId: number
  description?: string
  createdBy: AuditFieldUser
}

export interface CategoryTransactionItemBase {
  numberOfBags?: number
  comment?: string
}

export interface CategoryTransactionItemSummary extends CategoryTransactionItemBase {
  receivedAmount?: number
}

export interface CategoryTransactionItem {
  id: number
  comment?: string
  numberOfBags?: number
  receivedAmount?: number
  inputInventoryModifications: CategoryTransactionInventoryModification[]
  outputInventoryModifications: { amount: number }[]
  fileAttachments: FileAttachment[]
}

export interface CategoryTransactionBase {
  targetFactoryProfileHandlerId?: number
  targetFacilityId?: number
  statusId?: string
}

export interface CategoryTransactionSummary extends CategoryTransactionBase {
  id: number
  trackingCode: string
  sourceFacilityId: number
  targetFactoryProfileHandler?: FactoryProfileHandlerSummary
  targetFacility?: FacilitySummary
  sourceFacility?: FacilitySummary
  statusId: string
  orders: CategoryTransactionOrderSummary[]
  sendConfirmedAt?: Date
  receiveConfirmedAt?: Date
  report?: CategoryTransactionReportSummary
  hasQualityReport: boolean
}

export interface CategoryTransaction
  // Omit overwritten fields to prevent TypeScript errors if the new types aren't compatible with the base ones
  extends Omit<CategoryTransactionSummary, 'orders' | 'report' | 'targetFacility' | 'sourceFacility'> {
  orders: CategoryTransactionOrder[]
  report?: CategoryTransactionReport
  targetFacility?: FacilityAsPartnerOption
  sourceFacility?: FacilityAsPartnerOption
  senderFileAttachments: any // mixins.AssociationProxySequence[FileAttachment]
  receiverFileAttachments: any // mixins.AssociationProxySequence[FileAttachment]
}

export interface CategoryTransactionOrderBase {
  internalCode?: string
}

export interface CategoryTransactionOrderSummary extends CategoryTransactionOrderBase {
  id: number
  purchaseOrderId?: number
  purchaseOrder?: PurchaseOrderSummary
  items?: CategoryTransactionItemSummary[]
}

export interface CategoryTransactionOrder {
  id: number
  internalCode?: string
  purchaseOrder?: PurchaseOrder
  // TODO: Is actually an optional field on the backend
  items: CategoryTransactionItem[]
}

export interface CategoryTransactionReportSummary {
  statusId: string
  updatedAt?: Date
  items: CategoryTransactionReportItemSummary[]
}

export interface CategoryTransactionReport {
  statusId: string
  items: CategoryTransactionReportItem[]
}

export interface CategoryTransactionVolume {
  categoryTransactionId: number
  reportAmountKg: number
  inventoryAmountKg: number
  brandedInventoryAmountKg: number
  transactionAmountKg: number
}

interface FacilityShipmentsResponse {
  categoryTransactions: CategoryTransaction[]
  pagination: PaginationOffset
}

export const useFacilityShipments = (
  facilityId: number,
  limit: number,
  offset: number,
  query?: string,
  statuses?: ShipmentStatus[],
) =>
  useTokenQuery<FacilityShipmentsResponse, ErrorResponse>(
    [
      `v1/facilities/${facilityId}/category-transactions/`,
      getQueryParamsFromObject({ limit, offset, query, s: statuses }),
    ],
    undefined,
    { enabled: !!facilityId },
  )

interface IncomingShipmentsResponse {
  categoryTransactions: CategoryTransaction[]
  pagination: PaginationOffset
}

export const useIncomingShipments = (
  facilityId: number,
  limit: number,
  offset: number,
  query?: string,
  brandFilter?: BrandInfo,
) =>
  useTokenQuery<IncomingShipmentsResponse, ErrorResponse>(
    [
      `v1/facilities/${facilityId}/incoming-category-transactions/`,
      getQueryParamsFromObject({
        ...brandFilter,
        offset,
        limit,
        query,
      }),
    ],
    undefined,
    { enabled: !!facilityId },
  )

export const useIncomingShipmentPartitionModifications = (facilityId: number) =>
  useTokenQuery<
    { modificationPartitions: ModificationPartitionAggregate[] },
    ErrorResponse,
    ModificationPartitionAggregate[]
  >([`v1/facilities/${facilityId}/incoming-modification-partitions/`], undefined, {
    enabled: !!facilityId,
    select: (r) => r.modificationPartitions ?? [],
  })

export const useIncomingShipmentVolumes = (facilityId: number, brandFilter?: BrandInfo) =>
  useTokenQuery<{ categoryTransactionVolumes: CategoryTransactionVolume[] }, ErrorResponse>(
    [`v1/facilities/${facilityId}/incoming-category-transaction-volumes/`, getQueryParamsFromObject(brandFilter)],
    undefined,
    { enabled: !!facilityId },
  )

interface OrganizationCategoryTransactionsResponse {
  categoryTransactions: CategoryTransaction[]
  pagination: PaginationOffset
}

export const useCurrentOrganizationShipments = (limit: number, offset: number) =>
  useTokenQuery<OrganizationCategoryTransactionsResponse, ErrorResponse>([
    `v1/current-organization/category-transactions/`,
    { limit, offset },
  ])

export const useCurrentOrganizationIncomingShipments = (limit: number, offset: number) =>
  useTokenQuery<OrganizationCategoryTransactionsResponse, ErrorResponse>([
    `v1/current-organization/incoming-category-transactions/`,
    { limit, offset },
  ])

export interface ShipmentResponse {
  senderIsPartnered: boolean
  categoryTransaction: CategoryTransaction
  inventoryModifications: any[]
}

export const useShipment = (shipmentId: number) =>
  useTokenQuery<ShipmentResponse, ErrorResponse>([`v1/category-transactions/${shipmentId}/`], undefined, {
    enabled: !!shipmentId,
  })

export const useShipmentCreate = (facilityId: number) => {
  const post = useFetchUpdate('POST')
  const queryClient = useQueryClient()
  return useMutation(async (data: any) => post(`v1/facilities/${facilityId}/category-transactions/`, data), {
    onSuccess: async (response: any) => {
      queryClient.setQueryData([`v1/category-transactions/${response.categoryTransaction.id}/`], response)
      await Promise.all([
        queryClient.invalidateQueries([`v1/facilities/${facilityId}/category-transactions/`]),
        queryClient.invalidateQueries([`v1/current-organization/category-transactions/`]),
      ])
    },
  })
}

export const useShipmentUpdate = (facilityId: number, shipmentId: number) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate('PATCH')
  return useMutation((data: any) => patch(`v1/category-transactions/${shipmentId}/`, data), {
    onSuccess: async (response: any) => {
      queryClient.setQueryData([`v1/category-transactions/${shipmentId}/`], response)
      await Promise.all([
        ...response.categoryTransaction.orders.map((o) =>
          queryClient.invalidateQueries([`v1/category-transaction-orders/${o.id}/`]),
        ),
        queryClient.invalidateQueries([`v1/facilities/${facilityId}/category-transactions/`]),
        queryClient.invalidateQueries([`v1/current-organization/category-transactions/`]),
      ])
    },
  })
}

export const useShipmentDelete = (facilityId: number, shipmentId: number) => {
  const queryClient = useQueryClient()
  const del = useFetchDelete()
  return useMutation(() => del(`v1/category-transactions/${shipmentId}/`), {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries([`v1/facilities/${facilityId}/category-transactions/`]),
        queryClient.invalidateQueries([`v1/current-organization/category-transactions/`]),
        queryClient.invalidateQueries([`v1/facilities/${facilityId}/categories-with-stock/`]),
        queryClient.invalidateQueries(['v1/admin/category-transactions/', { skip: 0, limit: 300 }]),
      ])
      window.setTimeout(() => {
        // remove after resolving promise to prevent refreshing data before success is handled
        queryClient.removeQueries([`v1/category-transactions/${shipmentId}/`])
      })
    },
  })
}

export const useShipmentSendConfirm = (facilityId: number, shipmentId: number) => {
  const queryClient = useQueryClient()
  const put = useFetchUpdate('PUT')
  return useMutation(
    async (values: any) => put(`v1/category-transactions/${shipmentId}/status/`, { statusId: 'SENT_OUT', ...values }),
    {
      onSuccess: async (response) => {
        queryClient.setQueryData([`v1/category-transactions/${shipmentId}/`], response)
        await Promise.all([
          queryClient.invalidateQueries([`v1/facilities/${facilityId}/category-transactions/`]),
          queryClient.invalidateQueries([`v1/current-organization/category-transactions/`]),
        ])
      },
    },
  )
}

export const useShipmentStatusUpdate = (facilityId: number, shipmentId: number) => {
  const queryClient = useQueryClient()
  const put = useFetchUpdate('PUT')
  return useMutation((data: any) => put(`v1/category-transactions/${shipmentId}/status/`, data), {
    onSuccess: async (response) => {
      queryClient.setQueryData([`v1/category-transactions/${shipmentId}/`], response)
      await Promise.all([
        queryClient.invalidateQueries([`v1/facilities/${facilityId}/incoming-category-transactions/`]),
        queryClient.invalidateQueries([`v1/current-organization/incoming-category-transactions/`]),
      ])
    },
  })
}

export const useShipmentArriveConfirm = (facilityId: number, shipmentId: number) => {
  const queryClient = useQueryClient()
  const put = useFetchUpdate('PUT')
  return useMutation(
    (data: any) => put(`v1/category-transactions/${shipmentId}/status/`, { statusId: 'ARRIVED', ...data }),
    {
      onSuccess: async (response) => {
        queryClient.setQueryData([`v1/category-transactions/${shipmentId}/`], response)
        await Promise.all([
          queryClient.invalidateQueries([`v1/facilities/${facilityId}/incoming-category-transactions/`]),
          queryClient.invalidateQueries([`v1/current-organization/incoming-category-transactions/`]),
        ])
      },
    },
  )
}

export const useShipmentOrderCreate = (shipmentId: number) => {
  const queryClient = useQueryClient()
  const post = useFetchUpdate('POST')
  return useMutation(async (data: any) => post(`v1/category-transactions/${shipmentId}/orders/`, data), {
    onSuccess: async (response: any) => {
      const orderId = response.order.id
      const facilityId = response.order.transaction.sourceFacilityId

      queryClient.setQueryData([`v1/category-transaction-orders/${orderId}/`], response)
      await queryClient.invalidateQueries([`v1/category-transactions/${shipmentId}/`])

      await Promise.all([
        queryClient.invalidateQueries([`v1/facilities/${facilityId}/categories-with-stock/`]),
        queryClient.invalidateQueries([`v1/facilities/${facilityId}/category-transactions/`]),
        queryClient.invalidateQueries([`v1/current-organization/category-transactions/`]),
        queryClient.invalidateQueries([`v1/category-transactions/${shipmentId}/`]),
        ...(response?.order?.items ?? [])
          .map((item) => item?.inputInventoryModifications ?? [])
          .flat()
          .map(({ inventoryId, inventory }) => [
            queryClient.invalidateQueries([`v1/categories/${inventory.categoryId}/stock/`]),
            queryClient.invalidateQueries([`v1/categories/${inventory.categoryId}/inventory-modifications/`]),
            queryClient.invalidateQueries([`v1/category-inventories/${inventoryId}/modifications/`]),
            queryClient.invalidateQueries([`v1/category-inventories/${inventoryId}/`]),
          ])
          .flat(),
      ])
    },
  })
}

export const useShipmentOrder = (orderId: number) =>
  useTokenQuery<any, ErrorResponse>([`v1/category-transaction-orders/${orderId}/`], undefined, { enabled: !!orderId })

export const useShipmentOrderUpdate = (shipmentId: number, orderId: number) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate('PATCH')
  return useMutation((data: any) => patch(`v1/category-transaction-orders/${orderId}/`, data), {
    onSuccess: async (response: any) => {
      const facilityId = response.order.transaction.sourceFacilityId
      queryClient.setQueryData([`v1/category-transaction-orders/${orderId}/`], response)
      await Promise.all([
        queryClient.invalidateQueries([`v1/facilities/${facilityId}/categories-with-stock/`]),
        queryClient.invalidateQueries([`v1/facilities/${facilityId}/category-transactions/`]),
        queryClient.invalidateQueries([`v1/current-organization/category-transactions/`]),
        queryClient.invalidateQueries([`v1/category-transactions/${shipmentId}/`]),
        ...(response?.order?.items ?? [])
          .map((item) => item?.inputInventoryModifications ?? [])
          .flat()
          .map(({ inventoryId, inventory }) => [
            queryClient.invalidateQueries([`v1/categories/${inventory.categoryId}/stock/`]),
            queryClient.invalidateQueries([`v1/categories/${inventory.categoryId}/inventory-modifications/`]),
            queryClient.invalidateQueries([`v1/category-inventories/${inventoryId}/modifications/`]),
            queryClient.invalidateQueries([`v1/category-inventories/${inventoryId}/`]),
            queryClient.invalidateQueries([`v1/categories/${inventory.categoryId}/orders/${orderId}/`]),
          ])
          .flat(),
      ])
    },
  })
}

export const useAddCategoryToShipmentOrder = (
  facilityId: number,
  shipmentId: number,
  orderId: number,
  inventoryId: number,
) => {
  const post = useFetchUpdate('POST')
  const queryClient = useQueryClient()
  return useMutation(async (data: any) => post(`v1/category-transaction-orders/${orderId}/items/`, data), {
    onSuccess: async (result: any) => {
      await Promise.all([
        queryClient.invalidateQueries([`v1/category-transaction-orders/${orderId}/`]),
        queryClient.invalidateQueries([`v1/category-transactions/${shipmentId}/`]),
        queryClient.invalidateQueries([`v1/facilities/${facilityId}/categories-with-stock/`]),
        queryClient.invalidateQueries([`v1/category-inventories/${inventoryId}/modifications/`]),
        queryClient.invalidateQueries([`v1/category-inventories/${inventoryId}/`]),
        queryClient.invalidateQueries([
          `v1/categories/${result.categoryTransactionItem.inputInventoryModifications?.[0]?.inventory?.categoryId}/stock/`,
        ]),
        queryClient.invalidateQueries([
          `v1/categories/${result.categoryTransactionItem.inputInventoryModifications?.[0]?.inventory?.categoryId}/orders/${orderId}/`,
        ]),
      ])
    },
  })
}

export const useAddInventoriesToShipmentOrder = (facilityId: number, shipmentId: number, orderId: number) => {
  const queryClient = useQueryClient()
  const post = useFetchUpdate<unknown, number[]>('POST')
  return useMutation<unknown, unknown, number[]>(
    async (inventoryIds) => post(`v1/category-transaction-orders/${orderId}/inventories/`, inventoryIds),
    {
      onSuccess: async (_, inventoryIds) => {
        await Promise.all([
          queryClient.invalidateQueries([`v1/category-transaction-orders/${orderId}/`]),
          queryClient.invalidateQueries([`v1/category-transactions/${shipmentId}/`]),
          queryClient.invalidateQueries([`v1/facilities/${facilityId}/categories-with-stock/`]),
          ...inventoryIds.flatMap((inventoryId) => [
            queryClient.invalidateQueries([`v1/category-inventories/${inventoryId}/modifications/`]),
            queryClient.invalidateQueries([`v1/category-inventories/${inventoryId}/`]),
          ]),
        ])
      },
    },
  )
}

interface SmallIntClassifier {
  id: number
  name: string
}

interface Classifier {
  id: string
  name: string
}
interface DescriptionClassifier {
  id: number
  name: string
  descriptionRequired: boolean
}
export interface ActivitiesClassifier {
  stageId: number
  id: number
  name: string
  descriptionRequired: boolean
  appliesToHandler: boolean
  subActivities: { id: number; name: string; descriptionRequired: boolean }[]
}
interface WasteDisposalTypesClassifier {
  id: string
  groupId: string
  name: string
  isInHouse: boolean
  isRecyclerExclusive?: boolean
  isDeprecated?: boolean
}

interface RecycledProductOutput {
  id: string
  name: string
  asrCode: string
  isTextile?: boolean
  isHiddenInProfile?: boolean
}

export interface Brand {
  id: string
  name: string
  descriptionRequired: boolean
  canRegisterMaterial: boolean
  parentId: number
  isUnknown: boolean
  isDemo: boolean
  hasActiveMembership: boolean
}

export interface CountryClassifier {
  id: string
  name: string
  subdivisions: Classifier[]
}

export interface Classifiers {
  agents: DescriptionClassifier[]
  roles: { id: RoleId; name: string }[]
  countries: CountryClassifier[]
  processMaterialTypes: DescriptionClassifier[]
  productionUnits: Classifier[]
  brands: Brand[]
  brandPreferences: Classifier[]
  categoryWasteClassifications: Classifier[]
  categoryCompositionClassifications: Classifier[]
  facilityRecyclingStages: {
    id: number
    name: string
    isCollection: boolean
    appliesToHandler: boolean
    appliesToRecycler: boolean
    activities: ActivitiesClassifier[]
  }[]
  facilityRecyclingActivities: ActivitiesClassifier[]
  productionTypes: {
    id: number
    name: string
    isDefault: boolean
    profileProductionCompositionIds: number[]
    profileProductionMaterialTypes: Classifier[]
    productionMaterialTypeIds: number[]
    productionWasteTypeIds: number[]
    productionUnitIds: string[]
    isHiddenInProfile: boolean
  }[]
  wasteProcessingTypes: any[]
  roleIdsByEntity: {
    facility: RoleId[]
    organization: RoleId[]
    brand: RoleId[]
    project: RoleId[]
  }
  handlerFacilityTypes: { id: number; name: string; descriptionRequired?: boolean }[]
  handlerManagedMaterialProductionStages: {
    id: number
    name: string
    wasteTypes: { id: number; name: string; descriptionRequired: boolean; stageId: number }[]
  }[]
  handlerManagedMaterialWasteTypes: {
    id: number
    name: string
    stage: SmallIntClassifier
  }[]
  handlerCollectionSources: SmallIntClassifier[]
  handlerCollectedTextileTypes: SmallIntClassifier[]
  handlerSortingInputCategoryTypes: {
    id: number
    name: string
    descriptionRequired: boolean
    compositionsRequired: boolean
    categories: { id: number; name: string; descriptionRequired: boolean }[]
    compositions: SmallIntClassifier[]
    isDeprecated: boolean
  }[]
  handlerOutputCategoryTypes: {
    id: number
    name: string
    descriptionRequired: boolean
    compositionsRequired: boolean
    isTextileRelated: boolean
    categories: { id: number; name: string; descriptionRequired: boolean }[]
    compositions: SmallIntClassifier[]
  }[]
  handlerOutputActivities: SmallIntClassifier[]
  handlerOutputDestinations: SmallIntClassifier[]
  profileProductionCompositionGroups: {
    id: string
    name: string
    profileProductionCompositions: DescriptionClassifier[]
  }[]
  profileProductionCompositions: DescriptionClassifier[]
  handlerWasteDisposalTypes: SmallIntClassifier[]
  handlerDataManagementTypes: SmallIntClassifier[]
  yarnTypes: Classifier[]
  dyestuffTypes: Classifier[]
  productionWasteSubtypeGroups: { id: string; productionWasteSubtypes: Classifier[] }[]
  productionCompositions: { id: number; name: string; originalName: string; isTextile?: boolean }[]
  wasteDisposalTypes: WasteDisposalTypesClassifier[]
  wasteDisposalServices: { id: number; name: string; disposalTypes: WasteDisposalTypesClassifier[] }[]
  profileMaterialOrigins: SmallIntClassifier[]
  facilityTypes: Classifier[]
  projects: Classifier[]
  productionCertificates: { id: string; name: string; isFacilityLevel: boolean; descriptionRequired: boolean }[]
  productionMaterialTypes: {
    id: number
    name: string
    isColorRequired?: boolean
    isCompositionRequired?: boolean
    isTextile?: boolean
    isDeprecated?: boolean
  }[]
  profileProductionMaterialWeightTypes: (Classifier & { unitWeightKg: number | null })[]
  productionWasteTypes: SmallIntClassifier[]
  categoryInventoryModificationTypes: {
    id: number
    name: string
    shipmentTargetRequired: boolean
    isIncrement: boolean
    isUserSelectable: boolean
  }[]
  purchaseOrderDeliveryTerms: Classifier[]
  purchaseOrderPaymentTerms: Classifier[]
  purchaseOrderPaymentModes: Classifier[]
  purchaseOrderQualityLevels: Classifier[]
  purchaseOrderItemQuantityTypes: { id: PurchaseOrderQuantityTypeId; name: string }[]
  purchaseOrderItemDestinationTypes: { id: PurchaseOrderItemDestinationTypeId; name: string }[]
  purchaseOrderOrderDocumentTypes: SmallIntClassifier[]
  purchaseOrderStatuses: Classifier[]
  currencies: Classifier[]
  materialColorTypes: SmallIntClassifier[]
  materialColorGroups: {
    id: number
    name: string
    code: string
    colorTypeRequired: boolean
    tones: { id: number; name: string; isPastel: boolean; code: string }[]
  }[]
  colorClassifications: Classifier[]
  recycledProductMaterialStages: {
    id: string
    name: string
    isDefault: boolean
  }[]
  categoryTransactionStatuses: Classifier[]
  recycledProductStatuses: Classifier[]
  fabricTextureTypes: Classifier[]
  productionInputRecycledMaterials: Classifier[]
  disposalPaymentTypes: Classifier[]
  recyclingDestinationTypes: {
    id: string
    name: string
    descriptionRequired: boolean
  }[]
  rawMaterialCodes: {
    id: string
    code: string
    compositionId: number
    materialStageId: number
    isMixed: boolean
  }[]
  recycledProductOutputCategory: (RecycledProductOutput & {
    recycledProductOutputDetails: RecycledProductOutput[]
  })[]
  profilePreferences: Classifier[]
  recyclingServices: SmallIntClassifier[]
  settings: {
    PROFILE_TABS_FOR_COMPLETION: { MANUFACTURER: string[]; WASTE_HANDLER: string[]; RECYCLER: string[] }
  }
  fileAttachmentCategories: { id: number; name: string; descriptionRequired: boolean; originalName: string }[]
  materialSources: { id: string; name: string; description: string }[]
  colorChemicals: { id: number; name: string }[]
  recyclerOutputEndProductTypes: { id: number; name: string; descriptionRequired: boolean }[]
  recyclerOutputProductCompositions: { id: number; name: string }[]
  recyclerOutputProductColors: { id: number; name: string; descriptionRequired: boolean }[]
  recyclerOutputProductApplications: { id: number; name: string }[]
  recyclerOutputProductSpecifications: { id: number; name: string }[]
  recyclerProfileClientRequestAvailabilities: { id: number; name: string }[]
}

export function useClassifiers<T = Classifiers>(options?: UseQueryOptions<Classifiers, ErrorResponse, T, MyQueryKey>) {
  return useTokenQuery<Classifiers, ErrorResponse, T>(['v1/classifiers/'], undefined, options)
}

export function useGetCountryName() {
  const classifierQuery = useClassifiers()
  return useCallback(
    (countryId?: string): string | undefined =>
      countryId === undefined
        ? undefined
        : classifierQuery.isSuccess
          ? classifierQuery.data.countries.find((c) => c.id === countryId)?.name ?? countryId
          : undefined,
    [classifierQuery.isSuccess, classifierQuery.data?.countries],
  )
}

export const useFactoryProfile = (facilityId: number) =>
  useTokenQuery<any, ErrorResponse>([`v1/facilities/${facilityId}/factory-profile/`], undefined, {
    enabled: !!facilityId,
  })

export interface FactoryProfileHandlerSummaryForBrand {
  id: number
  name: string
  countryId?: string
  countrySubdivisionId?: string
  archivedAt?: Date
  isArchived?: boolean
}

export interface FactoryProfileHandlerSummary extends FactoryProfileHandlerSummaryForBrand {
  contact?: string
}

interface FactoryProfileHandlerResponse {
  handlers: { id: number; contact: string; name: string; isArchived: boolean }[]
  partnerFacilities: {
    id: number
    typeId: string
    name: string
    organization: { id: number; name: string }
    isDeleted: boolean
  }[]
}

export const useFactoryProfileHandlers = (
  facilityId?: number,
  options: UseQueryOptions<
    FactoryProfileHandlerResponse,
    ErrorResponse,
    FactoryProfileHandlerResponse,
    MyQueryKey
  > = {},
) =>
  useTokenQuery<FactoryProfileHandlerResponse, ErrorResponse>(
    [`v1/facilities/${facilityId}/factory-profile/handlers/`],
    undefined,
    {
      enabled: !!facilityId,
      ...options,
    },
  )

interface FactoryProfileBrandResponse {
  brands: {
    id: number
    brandId: number
    sharePercent: number
    description?: string
    parentId?: number
  }[]
}

export const useFactoryProfileBrands = <T extends object = FactoryProfileBrandResponse>(
  facilityId?: number,
  options: UseQueryOptions<FactoryProfileBrandResponse, ErrorResponse, T, MyQueryKey> = {},
) =>
  useTokenQuery<FactoryProfileBrandResponse, ErrorResponse, T>(
    [`v1/facilities/${facilityId}/factory-profile/brands/`],
    undefined,
    { enabled: !!facilityId, ...options },
  )

export const useFactoryProfileUpdate = (facilityId: number) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate('PATCH')
  return useMutation((data: any) => patch(`v1/facilities/${facilityId}/factory-profile/`, data), {
    onSuccess: async (response) => {
      queryClient.setQueryData([`v1/facilities/${facilityId}/factory-profile/`], response)
      await Promise.all([
        queryClient.invalidateQueries([`v1/facilities/`]),
        queryClient.invalidateQueries([`v1/facilities/${facilityId}/factory-profile/handlers/`]),
        queryClient.invalidateQueries([`v1/facilities/${facilityId}/categories-with-stock/`]),
      ])
    },
  })
}

export const useHandlerProfile = (facilityId: number) =>
  useTokenQuery<any, ErrorResponse>([`v1/facilities/${facilityId}/handler-profile/`], undefined, {
    enabled: !!facilityId,
  })

export const useHandlerProfileUpdate = (facilityId: number) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate('PATCH')
  return useMutation((data: any) => patch(`v1/facilities/${facilityId}/handler-profile/`, data), {
    onSuccess: async (response) => {
      queryClient.setQueryData([`v1/facilities/${facilityId}/handler-profile/`], response)
      await Promise.all([
        queryClient.invalidateQueries([`v1/facilities/`]),
        queryClient.invalidateQueries([`v1/facilities/${facilityId}/factory-profile/`]),
        queryClient.invalidateQueries([`v1/facilities/${facilityId}/factory-profile/handlers/`]),
      ])
    },
  })
}

export const useRecyclerProfile = (facilityId: number) =>
  useTokenQuery<any, ErrorResponse>([`v1/facilities/${facilityId}/recycler-profile/`], undefined, {
    enabled: !!facilityId,
  })

export const useRecyclerProfileUpdate = (facilityId: number) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate('PATCH')
  return useMutation((data: any) => patch(`v1/facilities/${facilityId}/recycler-profile/`, data), {
    onSuccess: async (response) => {
      queryClient.setQueryData([`v1/facilities/${facilityId}/recycler-profile/`], response)
      await Promise.all([
        queryClient.invalidateQueries([`v1/facilities/`]),
        queryClient.invalidateQueries([`v1/facilities/${facilityId}/recycler-profile/handlers/`]),
        queryClient.invalidateQueries([`v1/facilities/${facilityId}/factory-profile/`]),
        queryClient.invalidateQueries([`v1/facilities/${facilityId}/factory-profile/handlers/`]),
        queryClient.invalidateQueries([`v1/facilities/${facilityId}/recycler-profile/partners/`]),
      ])
    },
  })
}

export interface Survey {
  id: string
  [key: string]: any
}

export const useSurvey = (surveyId: string, options: UseQueryOptions<Survey, ErrorResponse, Survey, MyQueryKey> = {}) =>
  useTokenQuery<Survey, ErrorResponse>([`v1/factory-surveys/${surveyId}/`], undefined, {
    enabled: !!surveyId,
    ...options,
  })

export const useSurveyCreate = () => {
  const queryClient = useQueryClient()
  const post = useFetchUpdate<Survey>('POST')
  return useMutation<Survey, ErrorResponse>(() => post(`v1/factory-surveys/`, undefined), {
    onSuccess: async (response) => {
      queryClient.setQueryData([`v1/factory-surveys/${response.id}/`], response)
    },
  })
}

export const useSurveyUpdate = (surveyId: string) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate<Survey, object>('PATCH')
  return useMutation<Survey, ErrorResponse, object>((data) => patch(`v1/factory-surveys/${surveyId}/`, data), {
    onSuccess: async (response) => {
      queryClient.setQueryData([`v1/factory-surveys/${surveyId}/`], response)
    },
  })
}

export interface OrganizationResponse {
  organization: CurrentOrganization
}

export const useCurrentOrganization = () =>
  useTokenQuery<OrganizationResponse, ErrorResponse>(['v1/current-organization/'])
export const useCurrentOrganizationUpdate = () => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate('PATCH')
  return useMutation((data: any) => patch('v1/current-organization/', data), {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(['v1/current-organization/']),
        queryClient.invalidateQueries(['v1/current-user/']),
      ])
    },
  })
}
export const useCurrentOrganizationUsers = () => useTokenQuery([`v1/current-organization/users/`])

export const useCurrentOrganizationUserCreate = () => {
  const queryClient = useQueryClient()
  const post = useFetchUpdate('POST')
  return useMutation((data: any) => post(`v1/current-organization/users/for-all-roles/`, data), {
    onSuccess: async () => queryClient.invalidateQueries([`v1/current-organization/users/`]),
  })
}

export const useCurrentOrganizationUserUpdate = (userId: number) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate('PATCH')
  return useMutation((data: any) => patch(`v1/current-organization/users/${userId}`, data), {
    onSuccess: async () => queryClient.invalidateQueries([`v1/current-organization/users/`]),
  })
}

export const useCurrentOrganizationUserInvite = () => {
  const queryClient = useQueryClient()
  const post = useFetchUpdate('POST')
  return useMutation((data: any) => post(`v1/current-organization/users/`, data), {
    onSuccess: async () => queryClient.invalidateQueries([`v1/current-organization/users/`]),
  })
}
export const useCurrentOrganizationUserRemove = () => {
  const queryClient = useQueryClient()
  const del = useFetchDelete()
  return useMutation((userId: string) => del(`v1/current-organization/users/${userId}/`), {
    onSuccess: async () => queryClient.invalidateQueries([`v1/current-organization/users/`]),
  })
}

export const useFacilityPartnerOptions = (
  facilityId: number,
  facilityTypes: string[],
  options: UseQueryOptions<any, ErrorResponse, any, MyQueryKey> = {},
) =>
  useTokenQuery<any, ErrorResponse>(
    [`v1/facilities/${facilityId}/partner-options/`, { 'type-ids': facilityTypes }],
    undefined,
    {
      enabled: !!facilityId,
      ...options,
    },
  )

export const useFacilityRemove = (facilityId) => {
  const queryClient = useQueryClient()
  const del = useFetchDelete()
  return useMutation(() => del(`v1/facilities/${facilityId}/`), {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(['v1/facilities/', { skip: 0, limit: 300 }]),
        queryClient.removeQueries(`v1/facilities/${facilityId}/`),
      ])
    },
  })
}

export interface FacilityFeatures {
  poCreation: boolean
}

export const useFacilityFeatures = (facilityId?: number) =>
  useTokenQuery<FacilityFeatures, ErrorResponse>([`v1/facilities/${facilityId}/features/`], undefined, {
    enabled: !!facilityId,
  })

export const useCurrentFacilityFeatures = () => {
  const facilityQuery = useCurrentFacility()
  return useFacilityFeatures(facilityQuery.facilityId).data
}

export const useFacilityFeatureUpdate = (id: number) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate<null, FacilityFeatures>('PATCH')
  return useMutation<null, ErrorResponse, FacilityFeatures>(
    async (data) => await patch(`v1/facilities/${id}/features/`, data),
    {
      onSuccess: () => Promise.all([queryClient.invalidateQueries([`v1/facilities/${id}/features/`])]),
    },
  )
}

export interface CategoryTransactionReportItemSummary {
  categoryTransactionItemId?: number
  receivedAmount?: number
}

export interface CategoryTransactionReportItem {
  id: number
  categoryTransactionItemId: number // TODO: backend field is optional
  outputs: CategoryTransactionReportItemOutput[]
  receivedAmount: number
  reportInventoryModifications: CategoryTransactionInventoryModification[]
}

export interface CategoryInventoryModificationBase {
  amount: number
  brandId?: number
  brandName?: string
  agentId?: number
  agentName?: string
  typeId?: number
  targetFactoryProfileHandlerId?: number
}

// Added _Backend postfix because another interface with the name already exists and this one models the backend
export interface CategoryInventoryModification_Backend extends CategoryInventoryModificationBase {
  id: number
  inventoryId: number
}

export interface CategoryTransactionInventoryModification extends CategoryInventoryModification_Backend {
  inventory: CategoryInventoryWithCategory
}

export interface CategoryInventoryWithCategory {
  category?: CategoryWithCompositions
  sourceFacility?: FacilityAsPartnerOption
}

interface CategoryTransactionReportItemResponse {
  categoryTransactionReportItem: CategoryTransactionReportItem
}

export interface CategoryTransactionReportItemOutput {
  amount: number
  categoryId: number
  unitPrice?: number
  currencyId?: string
  category: CategoryWithCompositions
}

interface CategoryTransactionReportResponse {
  isEditable: boolean
  categoryTransactionReport?: {
    statusId: string
    items: CategoryTransactionReportItemResponse['categoryTransactionReportItem'][]
  }
}

export const useCategoryTransactionReport = (categoryTransactionId: number) =>
  useTokenQuery<CategoryTransactionReportResponse, ErrorResponse>(
    [`v1/category-transactions/${categoryTransactionId}/report/`],
    undefined,
    { enabled: !!categoryTransactionId },
  )

export const useShipmentConfirm = (facilityId: number, shipmentId: number) => {
  const queryClient = useQueryClient()
  const put = useFetchUpdate<unknown, object>('PUT')
  return useMutation<unknown, ErrorResponse>(
    () => put(`v1/category-transactions/${shipmentId}/status/`, { statusId: 'CONFIRMED' }),
    {
      onSuccess: async (response) => {
        queryClient.setQueryData([`v1/category-transactions/${shipmentId}/`], response)
        await Promise.all([
          queryClient.invalidateQueries([`v1/facilities/${facilityId}/incoming-category-transactions/`]),
          queryClient.invalidateQueries([`v1/current-organization/incoming-category-transactions/`]),
          queryClient.invalidateQueries([`v1/category-transactions/${shipmentId}/report/`]),
        ])
      },
    },
  )
}

export const useQualityReportStatusUpdate = (shipmentId: number, facilityId: number) => {
  const queryClient = useQueryClient()
  const put = useFetchUpdate<unknown, object>('PUT')
  return useMutation<unknown, ErrorResponse, string>(
    (statusId) => put(`v1/category-transactions/${shipmentId}/report/status/`, { statusId: statusId }),
    {
      onSuccess: async (response) => {
        queryClient.setQueryData([`v1/category-transactions/${shipmentId}/report`], response)
        await Promise.all([
          queryClient.invalidateQueries([`v1/facilities/${facilityId}/incoming-category-transactions/`]),
          queryClient.invalidateQueries([`v1/current-organization/incoming-category-transactions/`]),
          queryClient.invalidateQueries([`v1/category-transactions/${shipmentId}/report/`]),
          queryClient.invalidateQueries(['v1/admin/category-transactions/disputed/']),
        ])
      },
    },
  )
}

export const useCategoryTransactionReportItemUpdate = (
  categoryTransactionId: number,
  categoryTransactionItemId?: number,
  categoryTransactionReportItemId?: number,
) => {
  const queryClient = useQueryClient()
  const put = useFetchUpdate<unknown, CategoryTransactionReportItem>('PUT')
  return useMutation<unknown, ErrorResponse, any>(
    (data) =>
      put(
        `v1/category-transactions/${categoryTransactionId}/report/item/`,
        data,
        getQueryParamsFromObject({ categoryTransactionItemId }),
      ),
    {
      onSuccess: async () =>
        Promise.all([
          queryClient.invalidateQueries([`v1/category-transactions/${categoryTransactionId}/`]),
          queryClient.invalidateQueries([`v1/category-transactions/${categoryTransactionId}/report/`]),
          queryClient.invalidateQueries([`v1/category-transactions/${categoryTransactionId}/previous-outputs/`]),
          queryClient.invalidateQueries(['v1/admin/category-transactions/disputed/']),
          queryClient.invalidateQueries([
            `v1/category-transactions/${categoryTransactionId}/report/item/${categoryTransactionReportItemId}/unit-prices`,
          ]),
        ]),
    },
  )
}

export const useCategoryTransactionReportItemAmountUpdate = (
  categoryTransactionId: number,
  categoryTransactionItemId: number,
) => {
  const queryClient = useQueryClient()
  const put = useFetchUpdate<unknown, CategoryTransactionReportItem>('PUT')
  return useMutation<unknown, ErrorResponse, CategoryTransactionReportItem>(
    (data) =>
      put(`v1/category-transactions/${categoryTransactionId}/report/item-amount/${categoryTransactionItemId}/`, data),
    {
      onSuccess: async () =>
        Promise.all([
          queryClient.invalidateQueries([`v1/category-transactions/${categoryTransactionId}/`]),
          queryClient.invalidateQueries([`v1/category-transactions/${categoryTransactionId}/report/`]),
        ]),
    },
  )
}

export const useCategoryTransactionReportItemOutputConfirm = (
  categoryTransactionId: number,
  categoryTransactionReportItemId: number,
  facilityId: number,
) => {
  const queryClient = useQueryClient()
  const put = useFetchUpdate<unknown, object>('PUT')
  return useMutation<unknown, ErrorResponse, number>(
    (id: number) =>
      put(
        `v1/category-transactions/${categoryTransactionId}/report/item/${categoryTransactionReportItemId}/outputs/${id}/`,
        { isConfirmed: true },
      ),
    {
      onSuccess: async () =>
        Promise.all([
          queryClient.invalidateQueries([`v1/category-transactions/${categoryTransactionId}/`]),
          queryClient.invalidateQueries([`v1/category-transactions/${categoryTransactionId}/report/`]),
          // This is necessary to reflect inventory updates for the brand waste summary and columns for incoming shipments to the recycler
          queryClient.invalidateQueries([`v1/facilities/${facilityId}/incoming-modification-partitions/`]),
          queryClient.invalidateQueries([`v1/facilities/${facilityId}/incoming-category-transactions/`]),
        ]),
    },
  )
}

export const useGetOrCreateMatchingCategory = (facilityId: number, presetIdentifier: string) => {
  const queryClient = useQueryClient()
  const post = useFetchUpdate<CreateCategoryResponse>('POST')
  return useMutation<CreateCategoryResponse>(
    () =>
      post(`v1/facilities/${facilityId}/preset-categories/`, undefined, {
        'facility-id': facilityId,
        'preset-identifier': presetIdentifier,
      }),
    {
      onSuccess: async () => queryClient.invalidateQueries([`v1/facilities/${facilityId}/categories/`]),
    },
  )
}

export const useGetOrCreateCategoriesMatchingTransactionItemInputs = (
  facilityId: number,
  categoryTransactionId: number,
  categoryTransactionItemId: number,
  isProcessed: boolean,
  isBranded: boolean,
) => {
  const queryClient = useQueryClient()
  const post = useFetchUpdate<CategoriesResponse>('POST')
  return useMutation<CategoriesResponse>(
    () =>
      post(
        `v1/category-transactions/${categoryTransactionId}/items/${categoryTransactionItemId}/input-categories`,
        undefined,
        {
          'facility-id': facilityId,
          'is-processed': isProcessed,
          'is-branded': isBranded,
        },
      ),
    {
      onSuccess: async (response) =>
        queryClient.setQueryData<CategoriesResponse>([`v1/facilities/${facilityId}/categories/`], (oldData) => {
          const existingIds = map(oldData?.categories, 'id')
          return {
            ...oldData,
            categories: [
              ...response.categories.filter((c) => !existingIds.includes(c.id)),
              ...(oldData?.categories ?? []),
            ],
          }
        }),
    },
  )
}

export interface CategoryColor {
  colorGroupId: number
  colorToneId: number
  colorTypeId: number
  isBrightened: boolean
  description: string | null
}

interface CategoryCompositionBase {
  compositionId: number
  percentage: number
}

export interface CategoryCompositionInDBBase extends CategoryCompositionBase {
  id: number
}

export interface Partnership {
  partnerFacilityId: number
  isPreferred: boolean
  filters: { brandIds: number[] }[]
}

interface FacilityPartnerResponse {
  partnerships: Partnership[]
}

type FacilityPartnershipByPartnerId = { [key: Partnership['partnerFacilityId']]: Partnership }

export const useFacilityPreferredPartners = (facilityId?: number) =>
  useTokenQuery<FacilityPartnerResponse, ErrorResponse, FacilityPartnershipByPartnerId>(
    [`v1/facilities/${facilityId}/partnerships/`],
    undefined,
    {
      enabled: !!facilityId,
      select: (data) => keyBy(data.partnerships, (p) => p.partnerFacilityId),
    },
  )

export const useUpdateFacilityPreferredPartners = (facilityId?: number) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate<FacilityPartnerResponse, FacilityPartnerResponse>('PATCH')
  return useMutation<FacilityPartnerResponse, ErrorResponse, FacilityPartnershipByPartnerId>(
    (data) => patch(`v1/facilities/${facilityId}/partnerships/`, { partnerships: Object.values(data) }),
    {
      onSuccess: async (response) => {
        queryClient.setQueryData([`v1/facilities/${facilityId}/partnerships/`], response)
        await Promise.all([queryClient.invalidateQueries([`v1/recyclers/`])])
      },
    },
  )
}

export const useCategoryStock = (categoryId: number) =>
  useTokenQuery<
    {
      totalQuantityKg: number
      reservedQuantityKg: number
      draftQuantityKg: number
      isAllAvailableReserved: boolean
      reservedProductQuantityKg: number
    },
    ErrorResponse
  >([`v1/categories/${categoryId}/stock/`])

export const useCategoryPurchaseOrderItems = (categoryId: number, includeDrafts = false) =>
  useTokenQuery<{ purchaseOrderItems: any[] }, ErrorResponse, any[]>(
    [`v1/categories/${categoryId}/purchase-order-items/`, { 'include-drafts': includeDrafts }],
    undefined,
    { select: (r) => r.purchaseOrderItems },
  )

interface CreatePurchaseOrderRequest {
  facilityId: number
  categoryIds?: number[]
}
interface ReadOnlyPurchaseOrderResponse {
  purchaseOrder: ReadOnlyPurchaseOrder
}

export const usePurchaseOrderCreate = () => {
  const queryClient = useQueryClient()
  const { facilityId } = useCurrentFacility()
  const post = useFetchUpdate<ReadOnlyPurchaseOrderResponse, CreatePurchaseOrderRequest>('POST')

  return useMutation<ReadOnlyPurchaseOrderResponse, ErrorResponse, CreatePurchaseOrderRequest>(
    (data) => post(`v1/purchase-orders/`, data),
    {
      onSuccess: () => Promise.all([queryClient.invalidateQueries([`v1/facilities/${facilityId}/purchase-orders/`])]),
    },
  )
}

export const usePurchaseOrderDelete = (id: number) => {
  const queryClient = useQueryClient()
  const doDelete = useFetchDelete()
  const { facilityId } = useCurrentFacility()

  return useMutation<void, ErrorResponse>(async () => doDelete(`v1/purchase-orders/${id}/`), {
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries([`v1/facilities/${facilityId}/purchase-orders/`]),
        queryClient.removeQueries(`v1/purchase-orders/${id}/`),
      ]),
  })
}

interface PurchaseOrdersResponse {
  purchaseOrders: ReadOnlyPurchaseOrder[]
  pagination: Pagination
}

export const usePurchaseOrders = (facilityId: number, limit: number, skip: number, query?: string) =>
  useTokenQuery<PurchaseOrdersResponse, ErrorResponse>(
    [`v1/facilities/${facilityId}/purchase-orders/`, getQueryParamsFromObject({ limit, skip, query })],
    undefined,
  )

export const usePurchaseOrder = (id: number) =>
  useTokenQuery<{ purchaseOrder: ReadOnlyPurchaseOrder }, ErrorResponse, ReadOnlyPurchaseOrder>(
    [`v1/purchase-orders/${id}/`],
    undefined,
    { select: (o) => o.purchaseOrder },
  )

export const usePurchaseOrderUpdate = (id: number) => {
  const queryClient = useQueryClient()
  const patch = useFetchUpdate<{ purchaseOrder: ReadOnlyPurchaseOrder }, ReadOnlyPurchaseOrder>('PATCH')
  return useMutation<ReadOnlyPurchaseOrder, ErrorResponse, ReadOnlyPurchaseOrder>(
    async (values) => (await patch(`v1/purchase-orders/${id}/`, values)).purchaseOrder,
    {
      onSuccess: (response) => {
        queryClient.setQueryData([`v1/purchase-orders/${id}/`], { purchaseOrder: response })
      },
    },
  )
}

export const usePurchaseOrderShipments = (id: number) => {
  return useTokenQuery<ShipmentsResponse, ErrorResponse, any[]>(
    [`v1/purchase-orders/${id}/category-transactions/`],
    undefined,
    { select: (r) => r.categoryTransactions },
  )
}

interface PurchaseOrderTransactionItemResponse {
  items: PurchaseOrderItem[]
}

export const usePurchaseOrderTransactionItems = (id: number) => {
  return useTokenQuery<PurchaseOrderTransactionItemResponse, ErrorResponse, PurchaseOrder['items']>(
    [`v1/purchase-orders/${id}/transaction-items/`],
    undefined,
    { select: (r) => r.items, enabled: false },
  )
}

export type PurchaseOrderCategoryStock = { categoryId: number; quantityKg: number }[]

export const useAvailablePurchaseOrderCategoryStock = (purchaseOrderId: number) =>
  useTokenQuery<{ availableCategoryStock: PurchaseOrderCategoryStock }, ErrorResponse, PurchaseOrderCategoryStock>(
    [`v1/purchase-orders/${purchaseOrderId}/available-category-stock/`],
    undefined,
    { select: (o) => o.availableCategoryStock },
  )

export const usePurchaseOrderStatusUpdate = (id: number) => {
  const { facilityId } = useCurrentFacility()
  const queryClient = useQueryClient()
  const patch = useFetchUpdate<{ purchaseOrder: PurchaseOrder }, { statusId: PurchaseOrderStatusId }>('PATCH')
  return useMutation<PurchaseOrder, ErrorResponse, PurchaseOrderStatusId>(
    async (statusId) => (await patch(`v1/purchase-orders/${id}/status/`, { statusId })).purchaseOrder,
    {
      onSuccess: (response) =>
        Promise.all([
          queryClient.setQueryData([`v1/purchase-orders/${id}/`], { purchaseOrder: response }),
          queryClient.invalidateQueries([`v1/facilities/${facilityId}/purchase-orders/`]),
        ]),
    },
  )
}

export const usePurchaseOrdersForShipmentDestination = (facilityId?: number, shipmentDestinationId?: number) =>
  useTokenQuery<{ purchaseOrders: ReadOnlyPurchaseOrder[] }, ErrorResponse, { id: string; name: string }[]>(
    [
      `v1/facilities/${facilityId as number}/purchase-orders/`,
      { 'shipment-destination-id': shipmentDestinationId as number },
    ],
    undefined,
    {
      select: (o) => o.purchaseOrders.map((po) => ({ id: po.id.toString(), name: po.trackingCode })),
      enabled: !!(facilityId && shipmentDestinationId),
    },
  )

type PurchaseOrderItemWithPrice = { categoryId: string; supplierUnitPricePerKg: number }

export const usePreviousPurchaseOrderItems = (id: number, categoryIds: number[]) => {
  return useTokenQuery<{ items: PurchaseOrderItemWithPrice[] }, ErrorResponse, PurchaseOrderItemWithPrice[]>(
    [`v1/purchase-orders/${id}/previous-items/`, { 'category-ids': categoryIds }],
    undefined,
    { select: (r) => r.items, enabled: false },
  )
}

export const useRecyclerSupplierStock = ({
  brandIds,
  onlyPreferredPartners,
  wasteReceived,
  compositionIds,
  materialTypeIds,
  colorIds,
}: {
  brandIds: string[]
  onlyPreferredPartners: boolean
  wasteReceived: boolean
  compositionIds?: string[]
  materialTypeIds?: string[]
  colorIds?: string[]
}) =>
  useTokenQuery<RecyclerSuppliersSummaryResponse, ErrorResponse>([
    `v1/recyclers/supplier-stock/`,
    omitBy<any>(
      {
        skip: 0,
        limit: 300,
        'brand-ids': brandIds,
        'only-preferred-partners': onlyPreferredPartners,
        'waste-received': wasteReceived,
        'composition-ids': compositionIds,
        'material-type-ids': materialTypeIds,
        'color-ids': colorIds,
      },
      (p) => p === undefined,
    ),
  ])

export const useRecyclerCountrySuppliers = ({
  countryIds,
  brandIds,
  onlyPreferredPartners,
  wasteReceived,
  compositionIds,
  profileProductionCompositionIds,
  materialTypeIds,
  colorIds,
}: {
  countryIds?: string[]
  brandIds: string[]
  onlyPreferredPartners: boolean
  wasteReceived: boolean
  compositionIds?: string[]
  profileProductionCompositionIds?: string[]
  materialTypeIds?: string[]
  colorIds?: string[]
}) =>
  useTokenQuery<RecyclerSuppliersListResponse, ErrorResponse>([
    `v1/recyclers/suppliers/`,
    omitBy<any>(
      {
        skip: 0,
        limit: 300,
        'country-ids': countryIds,
        'brand-ids': brandIds,
        'only-preferred-partners': onlyPreferredPartners,
        'waste-received': wasteReceived,
        'composition-ids': compositionIds,
        'profile-production-composition-ids': profileProductionCompositionIds,
        'material-type-ids': materialTypeIds,
        'color-ids': colorIds,
      },
      (p) => p === undefined,
    ),
  ])

export interface CategoryTransactionOverviewResponse {
  groupedItems: CategoryTransactionOverviewItem[]
}

export interface CategoryTransactionOverviewItem {
  colorGroupId: number
  productionMaterialTypeId: number
  unsortedStockQuantityGm: number
  sortedStockQuantityGm: number
  compositionGroupId?: number
  compositionGroupName?: string
}

export function useTransactionOverview(facilityId?: number) {
  return useTokenQuery<CategoryTransactionOverviewResponse, ErrorResponse, CategoryTransactionOverviewItem[]>(
    [`v1/facilities/${facilityId}/transaction-overview/`],
    undefined,
    { enabled: !!facilityId, select: (r) => r.groupedItems },
  )
}
export const usePreferredByBrands = (facilityId?: number) =>
  useTokenQuery<{ preferredByBrandIds: number[] }, ErrorResponse, number[]>(
    [`v1/facilities/${facilityId}/preferred-by-brands/`],
    undefined,
    {
      enabled: !!facilityId,
      select: (r) => r.preferredByBrandIds ?? [],
    },
  )

interface ExternalPartner {
  id: number
  contact: string
  name: string
  isArchived: boolean
  countryId: string
}

interface RecyclerProfilePartnerResponse {
  externalPartners: ExternalPartner[]
  internalPartners: FacilityWithOrganization[]
}

export const useRecyclerProfilePartners = (facilityId: number) =>
  useTokenQuery<RecyclerProfilePartnerResponse, ErrorResponse>(
    [`v1/facilities/${facilityId}/recycler-profile/partners/`],
    undefined,
    {
      enabled: !!facilityId,
    },
  )

export interface UploadResponse {
  attachment: FileAttachment
  url: string
  fields: { [key: string]: string }
}

export interface UploadRequest {
  // category of the file
  categoryId: string
  // category description if category is "other"
  categoryDescription?: string
  // MIME type
  contentType: string
  // Custom suffix to add to the file name
  nameSuffix?: string
  fileSizeBytes: number
  // File's original name
  originalName: string
  // Optional description / comment on the attachment
  description?: string
  // Category Transaction Item's ID, if the file should be associated with it
  transactionItemId?: number
}

export type AttachmentUploadQuery = UseMutationResult<UploadResponse, ErrorResponse, UploadRequest>
export type AttachmentDeleteQuery = UseMutationResult<void, ErrorResponse, number>

export const useShipmentSenderAttachmentUpload = (shipmentId: number): AttachmentUploadQuery => {
  const queryClient = useQueryClient()
  const post = useFetchUpdate<UploadResponse, UploadRequest>('POST')
  return useMutation<UploadResponse, ErrorResponse, UploadRequest>(
    (data) => post(`v1/uploads/shipments/${shipmentId}/sender`, data),
    {
      onSuccess: async (response: any) => {
        queryClient.setQueryData([`v1/category-transactions/${shipmentId}/`], (oldData: any) =>
          appendDeepImmutable(oldData, `categoryTransaction.senderFileAttachments`, response.attachment),
        )
      },
    },
  )
}

export const useShipmentReceiverAttachmentUpload = (shipmentId: number): AttachmentUploadQuery => {
  const queryClient = useQueryClient()
  const post = useFetchUpdate<UploadResponse, UploadRequest>('POST')
  return useMutation<UploadResponse, ErrorResponse, UploadRequest>(
    (data) => post(`v1/uploads/shipments/${shipmentId}/receiver`, data),
    {
      onSuccess: async (response: any) => {
        await Promise.all([queryClient.invalidateQueries([`v1/category-transactions/${shipmentId}/`])])
      },
    },
  )
}

export interface DownloadResponse {
  url: string
}

export const useShipmentSenderAttachmentDownload = (shipmentId: number) => {
  const put = useFetchUpdate<UploadResponse>('PUT')
  return useMutation<DownloadResponse, ErrorResponse, number>((attachmentId) =>
    put(`v1/uploads/shipments/${shipmentId}/sender/${attachmentId}/`),
  )
}

export const useShipmentReceiverAttachmentDownload = (shipmentId: number) => {
  const put = useFetchUpdate<UploadResponse>('PUT')
  return useMutation<DownloadResponse, ErrorResponse, number>((attachmentId) =>
    put(`v1/uploads/shipments/${shipmentId}/receiver/${attachmentId}/`),
  )
}

export interface ShipmentAttachmentDownloadMutationParams {
  kind: 'sender' | 'receiver'
  shipmentId: number
  attachmentId: number
}
export const useShipmentAttachmentDownload = () => {
  const put = useFetchUpdate<UploadResponse>('PUT')
  return useMutation<DownloadResponse, ErrorResponse, ShipmentAttachmentDownloadMutationParams>(
    ({ shipmentId, kind, attachmentId }) => put(`v1/uploads/shipments/${shipmentId}/${kind}/${attachmentId}/`),
  )
}

export const useShipmentSenderAttachmentDelete = (shipmentId: number) => {
  const queryClient = useQueryClient()

  const del = useFetchDelete()
  return useMutation<void, ErrorResponse, number>(
    (attachmentId) => del(`v1/uploads/shipments/${shipmentId}/sender/${attachmentId}/`),
    {
      onSuccess: async (_, attachmentId) => {
        queryClient.setQueryData([`v1/category-transactions/${shipmentId}/`], (oldData: any) =>
          removeMatchingFromDeepImmutableList(oldData, `categoryTransaction.senderFileAttachments`, {
            id: attachmentId,
          }),
        )
      },
    },
  )
}

export const useShipmentReceiverAttachmentDelete = (shipmentId: number): AttachmentDeleteQuery => {
  const queryClient = useQueryClient()

  const del = useFetchDelete()
  return useMutation<void, ErrorResponse, number>(
    (attachmentId) => del(`v1/uploads/shipments/${shipmentId}/receiver/${attachmentId}/`),
    {
      onSuccess: async (_, attachmentId) => {
        await Promise.all([queryClient.invalidateQueries([`v1/category-transactions/${shipmentId}/`])])
      },
    },
  )
}

export const useCategoryTransactionReportItemPrefillPrices = (
  categoryTransactionId: number,
  categoryTransactionReportItemId: number,
  facilityId: number,
  purchaseOrderId?: number,
) => {
  return useTokenQuery<
    { categoryTransactionReportItem: CategoryTransactionReportItem },
    ErrorResponse,
    CategoryTransactionReportItem
  >(
    [
      `v1/category-transactions/${categoryTransactionId}/report/item/${categoryTransactionReportItemId}/unit-prices`,
      getQueryParamsFromObject({ facilityId, purchaseOrderId }),
    ],
    undefined,
    { select: (r) => r.categoryTransactionReportItem },
  )
}

export interface CategoryTransactionForRmdf {
  id: number
  trackingCode: string
  targetFacility: FacilitySummary
  sourceFacility: FacilitySummary
  statusId: string
  sendConfirmedAt?: Date
  receiveConfirmedAt?: Date
  senderFileAttachments: FileAttachment[]
  receiverFileAttachments: FileAttachment[]
}

interface RmdfCategoryComposition {
  name: string
  percentage: number
  rawMaterialCode: string
}
interface RmdfCategoryColour {
  group: string
  tone: string
  type: string
}
export interface RmdfItemCategory {
  product: string
  compositions: RmdfCategoryComposition[]
  colour?: RmdfCategoryColour
  materialType: string
  wasteType: string
}
export interface RmdfFacility {
  id: number
  name: string
  type: string
  textileExchangeId?: string
  location: string
  timestamp: Date
  isOrigin: boolean
  declaration: string
}
export interface RmdfItem {
  category: RmdfItemCategory
  facilityHistory: RmdfFacility[]
  categoryTransaction: string
  quantity: number
}
export interface RmdfCategoryTransaction {
  trackingCode: string
  sendConfirmedAt: Date
  receiveConfirmedAt: Date
  senderFileAttachments: any // mixins.AssociationProxySequence[FileAttachment]
  receiverFileAttachments: any // mixins.AssociationProxySequence[FileAttachment]
}

interface CategoryTransactionRmdfResponse {
  categoryTransaction: CategoryTransactionForRmdf
  categoryTransactionWeight: number
  items: RmdfItem[]
}

export const useCategoryTransactionRmdf = (categoryTransactionId: string) =>
  useTokenQuery<CategoryTransactionRmdfResponse, ErrorResponse>(
    [`v1/category-transactions/${categoryTransactionId}/rmdf/`],
    undefined,
    { enabled: !!categoryTransactionId },
  )

interface RmdfMonthlyResponse {
  id: string
  sourceFacility: FacilitySummary
  targetFacility: FacilitySummary
  targetScopeCertificate?: string
  totalWeightKg: number
  items: RmdfItem[]
  categoryTransactions: RmdfCategoryTransaction[]
}

export const useRmdfMonthly = (
  buyerId: string | undefined,
  supplierId: string | undefined,
  year: string | undefined,
  month: string | undefined,
) =>
  useTokenQuery<RmdfMonthlyResponse, ErrorResponse>([
    `v1/rmdf/`,
    getQueryParamsFromObject({ bid: buyerId, sid: supplierId, y: year, m: month }),
  ])

interface RmdfFacilitiesResponse {
  facilities: { id: number; name: string }[]
}

export const useRmdfFacilities = (
  facilityId: number,
  facilitiesKind: 'buyers' | 'suppliers',
  year: string,
  month: string,
) =>
  useTokenQuery<RmdfFacilitiesResponse, ErrorResponse>([
    `v1/rmdf/${facilityId}/${facilitiesKind}/`,
    getQueryParamsFromObject({ y: year, m: month }),
  ])
